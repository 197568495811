/**
=========================================================
* Clifford Inu
=========================================================
*/

import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import './styles.scss'
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
import TokensOverview from "layouts/dashboard/components/TokensOverview";
import NFTsOverview from "layouts/dashboard/components/NFTsOverview";

// utils
import {
  // balance,
  // transfers,
  // getCliffordItem,
  // getBalanceTransfers,
  portfolio,
  getPortfolioPrice,
  getPortfolioNumber,
  getPortfolioPriceHistory,
  getTopPortfolio,
} from "./utils";
import {
  // openSeaAssets,
  openSeaCollections,
  // getNFTPortfolioPrice,
  getOpenSeaCollectionsPrice,
  // getOpenSeaAssetsTable,
  getOpenSeaCollectionsTable,
  getOpenSeaCollecionsAssetsNumber,
  // greedykiwi,
  coingeckoETH,
} from "../nft/utils";

// Contracts
const cliffordContract = "0x1b9baf2a3edea91ee431f02d449a1044d5726669";
// const deadWallet = "0x000000000000000000000000000000000000dead";
const tokensWallet = "0x20c606fbb6984aaab0cad48f1f2644693adc1680";
const deployerWallet = "0x81ed317154e4c6e829b0358f59c5578719e95ccb";
// const devWallet = "0xfbc43ed5318cd614b672a338868a5db987fddc84";
// const uniswapWallet = "0xee09d851b4ba4642ab0f8a6ebebb1293fee9c0fb";

// Create our number formatter.
// const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  // const [price, setPrice] = useState(0);
  // const [priceYesterday, setPriceYesterday] = useState(0);

  // const [deadSupply, setDeadSupply] = useState(0);
  // const [deadSupplyHistory, setDeadSupplyHistory] = useState({});

  // const [priceLoader, setPriceLoader] = useState(0);
  const [tokens, setTokens] = useState(0);
  // const [tokensLastDay, setTokensLastDay] = useState(0);

  const [tokensNumber, setTokensNumber] = useState(0);
  const [tokensPriceHistory, setTokensPriceHistory] = useState({});
  const [tokensPriceHistory30D, setTokensPriceHistory30D] = useState({});

  const [tokensTop, setTokensTop] = useState([]);
  const [tokensLoader, setTokensLoader] = useState(0);
  // const [buyBacksLoader, setBuyBacksLoader] = useState(0);
  // const [buyBacks, setBuyBacks] = useState(parseFloat(localStorage.getItem("buyBacks") || 0));
  /* const [buyBacksBalance, setBuyBacksBalance] = useState(
    parseFloat(localStorage.getItem("buyBacksBalance") || 0)
  ); */
  /*  const [buyBacksHistory, setBuyBacksHistory] = useState(
    parseFloat(JSON.parse(localStorage.getItem("buyBacksHistory")) || {})
  ); */

  const [nftPortfolioLoader, setNFTPortfolioLoader] = useState(0);
  const [nftPortfolio, setNFTPortfolio] = useState(0);
  const [nftPortfolioNumber, setNFTPortfolioNumber] = useState(0);
  const [nftDataTable, setNFTDataTable] = useState([]);
  // const [nftPortfolioLastDay, setNFTPortfolioLastDay] = useState(0);

  useEffect(() => {
    /*   const fetchPrice = async (contract) => {
      setPriceLoader(1);
      try {
        const [responsePrice, responseDead] = await Promise.all([
          balance(contract),
          transfers(contract, deadWallet),
        ]);
        // responsePrice.data.data.items[0].quote_rate
        const itemPrice = getCliffordItem(responsePrice, cliffordContract);
        setPrice(itemPrice.quote_rate);
        setPriceYesterday(itemPrice.quote_rate_24h);
        const balanceDead = getBalanceTransfers(responseDead);
        setDeadSupply(balanceDead);
        //  const historyBalanceDead = getTransfersOneWeekHistory(responseDead, false);
        // setDeadSupplyHistory(historyBalanceDead);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setPriceLoader(0);
    }; */

    /* const fetchBuyBacks = async (contract, wallet) => {
      setBuyBacksLoader(1);
      try {
        const response = await transfers(contract, wallet);
        const priceBuyBacks = getBuyBacksPrice(response);
        // console.log("priceBuyBacks ", priceBuyBacks);
        const balanceBuyBacks = getBuyBacksBalance(response, cliffordContract);
        //  const historyBuyBacks = getPortfolioBalanceOneWeekHistory(response, cliffordContract, true);
        const historyBuyBacks = getTransfersOneWeekHistory(response, true);

        setBuyBacks(priceBuyBacks);
        localStorage.setItem("buyBacks", priceBuyBacks);
        setBuyBacksBalance(balanceBuyBacks);
        localStorage.setItem("buyBacksBalance", balanceBuyBacks);
        setBuyBacksHistory(historyBuyBacks);
        localStorage.setItem("buyBacksHistory", JSON.stringify(historyBuyBacks));
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setBuyBacksLoader(0);
    };
 */

    const fetchTokens = async (contract) => {
      setTokensLoader(1);
      try {
        const response = await portfolio(contract);
        // console.log("reponse tokens ", response);
        const priceTokensPortfolio = getPortfolioPrice(response);
        // const priceTokensPortfolioLastDay = getPortfolioPrice(response, 1);

        const numberTokens = getPortfolioNumber(response, cliffordContract, false);
        const historyTokensPrice = getPortfolioPriceHistory(response);
        const historyTokensPrice30D = getPortfolioPriceHistory(response, 30);

        const topTable = getTopPortfolio(response);

        // console.log("dominentToken in ", dominentToken);
        // console.log("topTable  ", topTable);

        setTokens(priceTokensPortfolio);
        // setTokensLastDay(priceTokensPortfolioLastDay);

        setTokensNumber(numberTokens);
        setTokensPriceHistory(historyTokensPrice);
        setTokensPriceHistory30D(historyTokensPrice30D);

        setTokensTop(topTable);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setTokensLoader(0);
    };

    const fetchNFTPortfolio = async (nftWallet, tokenWallet) => {
      setNFTPortfolioLoader(1);
      // setDatatableData([]);
      try {
        const ethResponse = await coingeckoETH();
        //  const responseNFT = await greedykiwi(nftWallet);
        //  const responseNFT2 = await greedykiwi(tokenWallet);

        //  const response = await balance(nftWallet);
        // const priceP = getBalanceNFTPrice(response);

        const datatable = [];
        const response = await openSeaCollections(tokenWallet);
        const responseNFT = await openSeaCollections(nftWallet);
        let priceP = await getOpenSeaCollectionsPrice(response);
        priceP += await getOpenSeaCollectionsPrice(responseNFT);
        await getOpenSeaCollectionsTable(response, datatable);
        await getOpenSeaCollectionsTable(responseNFT, datatable);

        let number = getOpenSeaCollecionsAssetsNumber(response);
        number += getOpenSeaCollecionsAssetsNumber(responseNFT);

        // const responseNFT = await balance(nftWallet);

        // console.log(response);
        /*  const datatable = [];
        let priceP = 0; */

        // let pricePP = 0;
        //  const tokenHash = [];
        //  pricePP += await getNFTPortfolioPrice(responseNFT, datatable, tokenHash);
        //  console.log(pricePP);
        // tokenHash = [];
        // priceP += await getNFTPortfolioPrice(responseNFT, datatable, tokenHash);
        /*  let priceP = 0;
        let pricePLastDay = 0;
        let number = 0;
        let dataHere = [];
        if (responseNFT) {
          priceP += parseFloat(
            responseNFT.data.portfolio.totalPrice * ethResponse.data.ethereum.usd
          );
          pricePLastDay += parseFloat(
            (responseNFT.data.portfolio.totalPrice - responseNFT.data.portfolio.oneDayChange) *
              ethResponse.data.ethereum.usd
          );
          number += parseFloat(responseNFT.data.portfolio.totalAssets);
          dataHere = responseNFT.data.collections;
        }
        if (responseNFT2) {
          priceP += parseFloat(
            responseNFT2.data.portfolio.totalPrice * ethResponse.data.ethereum.usd
          );
          pricePLastDay += parseFloat(
            (responseNFT2.data.portfolio.totalPrice - responseNFT2.data.portfolio.oneDayChange) *
              ethResponse.data.ethereum.usd
          );
          number += parseFloat(responseNFT2.data.portfolio.totalAssets);
          if (dataHere.length > 0) {
            dataHere = dataHere.concat(responseNFT2.data.collections);
          } else {
            dataHere = responseNFT2.data.collections;
          }
        } */

        setNFTPortfolio(priceP * ethResponse.data.ethereum.usd);
        // setNFTPortfolioLastDay(priceP * ethResponse.data.ethereum.usd);
        setNFTPortfolioNumber(number);

        //  setNFTPortfolioNumber(datatable.length);

        //  setNFTPortfolioNumber(datatable.length);
        // console.log("nftPortfolioNumber ", nftPortfolioNumber);
        setNFTDataTable(datatable);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setNFTPortfolioLoader(0);
    };

    const refresh = async () => {
      await Promise.all([
        // fetchPrice(cliffordContract),
        // fetchBuyBacks(cliffordContract, tokensWallet),
        fetchTokens(tokensWallet),
        fetchNFTPortfolio(deployerWallet, tokensWallet),
      ]);
    };

    refresh();
  }, []);

  return (
    <DashboardLayout>
      {/* <MDBox display="flex"> */}
      <DashboardNavbar width={'71.3%'} />
      <Grid item xs={0} md={0} lg={4} className="NFTDesktop" width={'24%'} position={'fixed'} height={'100%'} right={'0px'} top={'0px'}> 
        <NFTsOverview nftDataTable={nftDataTable} assetsNumber={nftPortfolioNumber} />
      </Grid>
      {/* </MDBox> */}
      <MDBox py={3}>
        <Grid container spacing={3} className="clifdashboardSection">
          <div className="clifdashboard">
            
            <Grid item xs={11} md={12} lg={8}>
                <MDBox display="flex" flexWrap="wrap" >
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2} >
                      <ComplexStatisticsCard
                        color="dark"
                        icon={(
                          <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.5255 14.5347C28.8891 15.1711 28.182 15.5954 27.4749 15.8783C25.1414 16.9389 22.3837 16.4439 20.4745 14.5347C17.9289 11.9892 17.9996 7.95865 20.4745 5.48378C22.9494 3.00891 26.9799 2.9382 29.5255 5.48378C31.4347 7.39297 31.8589 10.2214 30.869 12.4841C30.5861 13.1912 30.1619 13.8984 29.5255 14.5347Z" fill="white"/>
                            <path opacity="0.6" d="M35.4653 20.4743C34.8289 21.1107 34.4047 21.8178 34.1218 22.5249L27.475 15.8781C28.1821 15.5953 28.8892 15.171 29.5256 14.5346C30.0913 13.9689 30.5863 13.1911 30.8691 12.484L37.5159 19.1308C36.8088 19.4136 36.1017 19.8379 35.4653 20.4743Z" fill="white"/>
                            <path d="M14.5352 29.5254C13.8989 30.1618 13.1917 30.586 12.4846 30.8689C10.1512 31.9295 7.39347 31.4346 5.48428 29.5254C2.9387 26.9798 2.9387 23.02 5.48428 20.4744C7.95916 17.9995 11.9897 17.9288 14.5352 20.4744C16.4444 22.3836 16.8687 25.212 15.8788 27.4748C15.5252 28.2526 15.1009 28.9597 14.5352 29.5254Z" fill="white"/>
                            <path d="M44.5167 29.5255C42.0418 32.0004 37.9406 32.0004 35.4657 29.5255C33.5565 27.6164 33.0616 24.8586 34.1222 22.5252C34.4051 21.8181 34.8293 21.111 35.4657 20.4746C36.0314 19.9089 36.8092 19.4139 37.5163 19.1311C39.7791 18.1411 42.6075 18.5654 44.5167 20.4746C46.9916 22.9495 46.9916 27.0507 44.5167 29.5255Z" fill="white"/>
                            <path d="M29.5255 44.5162C27.0506 46.9911 22.9494 46.9911 20.4745 44.5162C18.5653 42.607 18.0704 39.8493 19.131 37.5158C19.4139 36.8087 19.8381 36.1016 20.4745 35.4652C21.0402 34.8995 21.818 34.4046 22.5251 34.1217C24.7879 33.1318 27.6163 33.556 29.5255 35.4652C32.0004 37.9401 32.0004 42.0413 29.5255 44.5162Z" fill="white"/>
                            <path opacity="0.6" d="M20.4741 35.4649C19.8377 36.1013 19.4135 36.8084 19.1306 37.5155L12.4838 30.8687C13.1909 30.5859 13.898 30.1616 14.5344 29.5252C15.1001 28.9596 15.5951 28.1817 15.8779 27.4746L22.5247 34.1214C21.7469 34.475 21.0398 34.8993 20.4741 35.4649Z" fill="white"/>
                            <path opacity="0.6" d="M35.4656 29.5256C36.102 30.162 36.8091 30.5862 37.5162 30.8691L30.8694 37.5159C30.5865 36.8088 30.1623 36.1017 29.5259 35.4653C28.9602 34.8996 28.1824 34.4046 27.4753 34.1218L34.1221 27.475C34.4756 28.2528 34.8999 28.9599 35.4656 29.5256Z" fill="white"/>
                            <path opacity="0.6" d="M20.4744 14.5348C21.1108 15.1712 21.8179 15.5954 22.525 15.8783L15.8782 22.5251C15.5953 21.818 15.1711 21.1109 14.5347 20.4745C13.969 19.9088 13.1912 19.4138 12.4841 19.131L19.1309 12.4842C19.4137 13.1913 19.838 13.8984 20.4744 14.5348Z" fill="white"/>
                          </svg>
                        )}
                        title="Net worth"
                        count={
                          !tokensLoader && !nftPortfolioLoader ? (
                            <div>{formatterUS.format(tokens + parseFloat(nftPortfolio))}</div>
                          ) : (
                            <CircularProgress size={26} />
                          )
                        }
                        /*  percentage={{
                          color: "success",
                          amount: !tokensLoader ? (
                            <div>{formatter.format(tokensNumber)}</div>
                          ) : (
                            <CircularProgress size={26} />
                          ),
                          label: " assets",
                        }} */
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2} mr={1} ml={1}>
                      <ComplexStatisticsCard
                        color="primary"
                        icon={(
                          <svg width="40" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0656 30C17.1769 30 22.1311 25.0458 22.1311 18.9344C22.1311 12.8231 17.1769 7.86885 11.0656 7.86885C4.95423 7.86885 0 12.8231 0 18.9344C0 25.0458 4.95423 30 11.0656 30Z" fill="#FFFDF5"/>
                            <path opacity="0.6" d="M18.9347 0C15.4921 0 12.2953 1.72131 10.3281 4.18033C10.574 4.18033 10.8199 4.18033 11.0658 4.18033C19.1806 4.18033 25.8199 10.8197 25.8199 18.9344C25.8199 19.1803 25.8199 19.4262 25.8199 19.6721C28.2789 17.7049 30.0003 14.5082 30.0003 11.0656C30.0003 4.91803 25.0822 0 18.9347 0Z" fill="#FFFDF5"/>
                          </svg>
                        )}
                        title="Tokens worth"
                        count={
                          !tokensLoader ? (
                            <div>{formatterUS.format(tokens)}</div>
                          ) : (
                            <CircularProgress size={26} />
                          )
                        }
                        /*  percentage={
                          !tokensLoader ? (
                            {
                              color: tokens >= tokensLastDay ? "success" : "error",
                              amount: tokensLastDay
                                ? `${parseFloat((tokens / tokensLastDay - 1) * 100).toFixed(2)}% (${
                                    tokens > tokensLastDay ? "+" : ""
                                  }${formatterUS.format(tokens - tokensLastDay)})`
                                : 0,
                              label: "",
                            }
                          ) : (
                            <CircularProgress size={26} />
                          )
                        } */
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2} >
                      <ComplexStatisticsCard
                        color="success"
                        icon={(
                          <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.4034 0V21.0129L0 15.6137L12.4034 0Z" fill="#FFFDF5"/>
                            <path opacity="0.6" d="M24.8068 15.6137L12.4033 21.0129V0L24.8068 15.6137Z" fill="#FFFDF5"/>
                            <path opacity="0.6" d="M24.8068 20.5751L12.4033 34V25.8283L24.8068 20.5751Z" fill="#FFFDF5"/>
                            <path d="M12.4034 25.8283V34L0 20.5751L12.4034 25.8283Z" fill="#FFFDF5"/>
                          </svg>

                        )}
                        title="NFT worth"
                        count={
                          !nftPortfolioLoader ? (
                            <div>{formatterUS.format(nftPortfolio)}</div>
                          ) : (
                            <CircularProgress size={26} />
                          )
                        }
                        /* percentage={
                          !nftPortfolioLoader ? (
                            {
                              color: nftPortfolio >= nftPortfolioLastDay ? "success" : "error",
                              amount:
                                nftPortfolioLastDay > 0
                                  ? `${parseFloat((nftPortfolio / nftPortfolioLastDay - 1) * 100).toFixed(
                                      2
                                    )}% (${
                                      nftPortfolio > nftPortfolioLastDay ? "+" : ""
                                    }${formatterUS.format(nftPortfolio - nftPortfolioLastDay)})`
                                  : 0,
                              label: "",
                            }
                          ) : (
                            <CircularProgress size={26} />
                          )
                        } */
                      />
                    </MDBox>
                  </Grid>
                </MDBox>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Token Worth (USD)"
                    
                    date="just updated"
                    chart={tokensPriceHistory}
                    chart2={tokensPriceHistory30D}
                    button
                  />
                </MDBox>
                <Grid item xs={12} md={12} lg={12}>
                  {useMemo(
                    () => (
                      <MDBox mb={0}>
                        <TokensOverview
                          tokensTop={tokensTop}
                          tokensNumber={tokensNumber}
                          tokensPortoflioValue={tokens}
                        />
                      </MDBox>
                    ),
                    [tokensTop, tokensNumber]
                  )}
                </Grid>
                <Grid className="NFTMobile" item mt={10} xs={12} md={12} lg={0}> 
                  <NFTsOverview nftDataTable={nftDataTable} assetsNumber={nftPortfolioNumber} />
                </Grid>
            </Grid>
          </div>
         
        </Grid>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            
            
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {/*  <Projects /> */}
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              {/* <OrdersOverview /> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
