/**
=========================================================
* Clifford Inu
=========================================================
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";
import './styles.scss'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage={},
  icon,
  icon2,
}) {
  return (
    <Card className="statCard">
      <MDBox display="flex" alignItems="center" height={Object.keys(percentage).length>0 ? '80px' : "120px"} bgColor="#080808" borderRadius="8px">
        {icon && <div className="DbIconBox">{icon}</div>}
        
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} paddingRight="unset">
          <MDBox textAlign="left" lineHeight={1.25}>
            <MDTypography variant="button" fontWeight="light" color="text">
              {title}
            </MDTypography>
            <MDTypography variant="h4" color={color}>{count}</MDTypography>
          </MDBox>
        </MDBox>
        {icon2 && <div className="DbIcon2Box">{icon2}</div>}
      </MDBox>
      
      {Object.keys(percentage).length>0 &&  
          <>
            <Divider
              variant="fullWidth"
                light={false}
            />
          <MDBox pb={2} px={2}>
            <MDTypography component="p" variant="button" color="text" display="flex">
              <MDTypography
                component="span"
                variant="button"
                fontWeight="bold"
                color={percentage.color}
              >
                {percentage.amount} &nbsp; 
                <span className="statPercentLabel">{percentage.label}</span>
              </MDTypography>
            </MDTypography>
          </MDBox>
          </>
          
      }
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  // icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
