/**
=========================================================
* Clifford Inu
=========================================================
*/
import React from "react";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// import Icon from "@mui/material/Icon";
// import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
// import TimelineItem from "examples/Timeline/TimelineItem";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ImageCard from "examples/Cards/InfoCards/ImageCard";

// import { openSeaCollections, getOpenSeaCollecionsAssetsNumber } from "../../../nft/utils";

// const deployerWallet = "0x81ed317154e4c6e829b0358f59c5578719e95ccb";
const formatter = new Intl.NumberFormat("en-US", {});

function NFTsOverview({ nftDataTable, assetsNumber }) {
  /* const [nftPortfolioLoader, setNFTPortfolioLoader] = useState(0);
  const [nftCollectionsNumber, setNFTCollectionsNumber] = useState(0);
  const [nftAssetsNumber, setNFTAssetsNumber] = useState(0);
 */
  /*  useEffect(() => {
    const fetchNFTCollections = async (nftWallet) => {
      setNFTPortfolioLoader(1);
      try {
        const response = await openSeaCollections(nftWallet);

        const assetsNumber = await getOpenSeaCollecionsAssetsNumber(response);
        //   const assetsNumber = await getOpenSeaAssetsNumber(response);

        setNFTCollectionsNumber(response.data.length);
        setNFTAssetsNumber(assetsNumber);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setNFTPortfolioLoader(0);
    };

    const refresh = async () => {
      await Promise.all([fetchNFTCollections(deployerWallet)]);
    };

    refresh();
  }, []); */

  return (
    <Card className="dbNftCard">
      <MDBox pt={3} px={3}>
        <MDTypography variant="h4" fontWeight="medium">
          NFTS
        </MDTypography>
        {/*   <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              24%
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox> */}
      </MDBox>

      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="success"
                title="Collections"
                description={<div>{formatter.format(nftDataTable.length)}</div>}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="success"
                title="NFTS"
                description={<div>{formatter.format(assetsNumber)}</div>}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="nftTokenImages">
          {nftDataTable
            .filter((item, idx) => idx < 24)
            .map((item) => (
              <Grid item xs={2} md={2} lg={3}>
                <MDBox width="3rem" sx={{margin:'10px'}} height="3rem">
                  <ImageCard
                    color="success"
                    // title="NFTS"
                    // description={<div>{formatter.format(nftAssetsNumber)}</div>}
                    img={item.imageUrl}
                    link={item.permalink}
                    icon="notifications"
                  />
                </MDBox>
              </Grid>
            ))}
        </Grid>

        {/* 
          <TimelineItem
            color="success"
            icon="notifications"
            title="$2400, Design changes"
            dateTime="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="error"
            icon="inventory_2"
            title="New order #1832412"
            dateTime="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="shopping_cart"
            title="Server payments for April"
            dateTime="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="payment"
            title="New card added for order #4395133"
            dateTime="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="vpn_key"
            title="New card added for order #4395133"
            dateTime="18 DEC 4:54 AM"
            lastItem
          /> */}
        <MDBox p={2} mt="auto">
          <MDButton
            component="a"
            href="/nfts"
            rel="noreferrer"
            variant="gradient"
            color="primary"
            fullWidth
            sx={{position:'absolute',width:'80%',bottom:'20px'}}
          >
            Open NFTs
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
NFTsOverview.defaultProps = { nftDataTable: [], assetsNumber: 0 };

// Typechecking props for the DefaultInfoCard
NFTsOverview.propTypes = {
  nftDataTable: PropTypes.arrayOf(PropTypes.object),
  assetsNumber: PropTypes.number,
};

export default NFTsOverview;
