/**
=========================================================
* Clifford Inu
=========================================================
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

function ReportsBarChart({
  color,
  title,
  description,
  // date,
  icon,
  chart,
}) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <Card sx={{ height: "100%",background:'#080808' }}>
      <MDBox padding="0rem">
        {useMemo(
          () => (
            <MDBox
              // variant="gradient"
              bgColor={'#080808'}
              borderRadius="lg"
              // coloredShadow={color}
              px={2}
              pr={2}
              mt={0}
              pb={7}
              height="14rem"
            >
              <div style={{display:'flex',marginTop:'20px',alignItems:'center',marginLeft:'20px'}}>
              {icon}
              <MDTypography variant="h6"  textTransform="capitalize" marginLeft='10px'>
                {title}
              </MDTypography>
              </div>
              <MDTypography component="div" variant="button" color="text" fontWeight="light">
                {description}
              </MDTypography>
              <Bar data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
