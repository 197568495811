/**
=========================================================
* Clifford Inu
=========================================================
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
// Import DAppProvider
import { DAppProvider } from "@usedapp/core";
// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

ReactDOM.render(
  <DAppProvider config={{}}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </DAppProvider>,
  document.getElementById("root")
);
