/**
=========================================================
* Clifford Inu
=========================================================
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import './styles.scss'

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        separator={<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6" stroke="#7B818C" stroke-width="2" stroke-linecap="round"/>
        <path d="M1 11L6 6" stroke="#7B818C" stroke-width="2" stroke-linecap="round"/>
        </svg>
        }
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="div"
            className="homeCrumb "
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <span className="svgSection">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.68149 4.16497C9.25339 3.94664 8.74661 3.94664 8.31851 4.16497L2.81851 6.96997C2.31621 7.22614 2 7.74236 2 8.30622V15C2 16.6568 3.34315 18 5 18H5.86047C6.48981 18 7 17.4898 7 16.8604V14C7 12.8954 7.89543 11.9999 9 11.9999C10.1046 11.9999 11 12.8954 11 14V16.8604C11 17.4898 11.5102 18 12.1395 18H13C14.6569 18 16 16.6568 16 15V8.30622C16 7.74237 15.6838 7.22614 15.1815 6.96997L9.68149 4.16497Z" fill="#7B818C"/>
              <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M0.105792 5.44721C0.352781 5.94119 0.953454 6.14141 1.44743 5.89442L9.00022 2.11803L16.553 5.89442C17.047 6.14141 17.6477 5.94119 17.8946 5.44721C18.1416 4.95323 17.9414 4.35256 17.4474 4.10557L9.44743 0.105567C9.1659 -0.0351963 8.83453 -0.0351963 8.55301 0.105567L0.553005 4.10557C0.059027 4.35256 -0.141197 4.95323 0.105792 5.44721Z" fill="#7B818C"/>
            </svg>

            </span>
           <span className="breadHome crumbEle" >Home</span>
          </MDTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              className="crumbEle"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
             <span className="crumbEle">{el}</span> 
            </MDTypography>
          </Link>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
           <span className="crumbEle">{title.replace("-", " ")}</span>
        </MDTypography>
      </MuiBreadcrumbs>
      
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
