/**
=========================================================
* Clifford Inu
=========================================================
*/

import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import './styles.scss'

// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  // navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini, width="100%" }) {
  const { account, deactivate } = useEthers();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const headerCss = `
    header{
      width: ${width} !important;
    }
  `
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <style>
        {headerCss}
      </style>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/*  <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/authentication/sign-in/basic">
                {/* <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton> */}
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <MDBox display="flex">
                <a href="https://app.uniswap.org/#/swap?outputCurrency=0x1b9baf2a3edea91ee431f02d449a1044d5726669">
                  <button className="buyCliff">Buy Cliff</button>
                </a>
             

              {account ? (
                 <button
                 className="connectWltBtn"
                 onClick={deactivate}
                  >
                    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.392377 1.71993C0 2.39375 0 3.27583 0 5.04V11.76C0 13.5242 0 14.4062 0.392377 15.0801C0.737521 15.6728 1.28825 16.1547 1.96563 16.4567C2.73572 16.8 3.74381 16.8 5.76 16.8H18.24C20.2562 16.8 21.2643 16.8 22.0344 16.4567C22.7118 16.1547 23.2625 15.6728 23.6076 15.0801C24 14.4062 24 13.5242 24 11.76C24 11.644 23.8925 11.55 23.76 11.55H18C16.0118 11.55 14.4 10.1397 14.4 8.4C14.4 6.6603 16.0118 5.25 18 5.25H23.76C23.8925 5.25 24 5.15598 24 5.04C24 3.27583 24 2.39375 23.6076 1.71993C23.2625 1.12722 22.7118 0.645331 22.0344 0.343329C21.2643 0 20.2562 0 18.24 0H5.76C3.74381 0 2.73572 0 1.96563 0.343329C1.28825 0.645331 0.737521 1.12722 0.392377 1.71993Z" fill="#FFFDF5"></path>
                    </svg>
                    <MDTypography variant="button">${account.substring(0, 4)}...
                    {account.substring(account.length - 4, account.length)}</MDTypography>
                  </button>
              ) : (
                <button
                  className="connectWltBtn"
                  onClick={handleConfiguratorOpen}
                  
                >
                  <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.392377 1.71993C0 2.39375 0 3.27583 0 5.04V11.76C0 13.5242 0 14.4062 0.392377 15.0801C0.737521 15.6728 1.28825 16.1547 1.96563 16.4567C2.73572 16.8 3.74381 16.8 5.76 16.8H18.24C20.2562 16.8 21.2643 16.8 22.0344 16.4567C22.7118 16.1547 23.2625 15.6728 23.6076 15.0801C24 14.4062 24 13.5242 24 11.76C24 11.644 23.8925 11.55 23.76 11.55H18C16.0118 11.55 14.4 10.1397 14.4 8.4C14.4 6.6603 16.0118 5.25 18 5.25H23.76C23.8925 5.25 24 5.15598 24 5.04C24 3.27583 24 2.39375 23.6076 1.71993C23.2625 1.12722 22.7118 0.645331 22.0344 0.343329C21.2643 0 20.2562 0 18.24 0H5.76C3.74381 0 2.73572 0 1.96563 0.343329C1.28825 0.645331 0.737521 1.12722 0.392377 1.71993Z" fill="#FFFDF5"></path>
                  </svg>
                  <MDTypography variant="button">Connect Wallet</MDTypography>
                </button>
              )}
              </MDBox>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
