import { formatUnits } from "@ethersproject/units";

// Soft UI Dashboard React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDBadge from "components/MDBadge";

import axios from "axios";

axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";
/* 
const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}); */
export async function transfers(contract, wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}/` +
        `transfers_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3&contract-address=${contract}&page-size=1000`
    ); */

    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/transfers_v2/${wallet}/${contract}/1`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function portfolio(chainId, wallet) {
  try {
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/portfolio_v2/${wallet}/${chainId}`
    );
  } catch (error) {
    console.error(error.message);
  }
  return null;
}

export async function balance(contract) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${contract}/` +
        `balances_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/balances_v2/${contract}/false`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export const getBalanceTransfers = (response) => {
  let balanceTransfers = 0;
  response.data.data.data.items.map((item) => {
    balanceTransfers += parseFloat(formatUnits(item.transfers[0].delta, 18));
    return null;
  });
  return balanceTransfers.toFixed(0);
};

export const getBalanceTransfersToday = (response) => {
  let balanceTransfersToday = 0;
  const today = new Date();

  response.data.data.data.items.map((item) => {
    const date = new Date(item.block_signed_at);

    if (
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    )
      balanceTransfersToday += parseFloat(formatUnits(item.transfers[0].delta, 18));
    return null;
  });
  return balanceTransfersToday.toFixed(0);
};

export const getItem = (response, contract) => {
  let itemToReturn;
  response.data.data.data.items.map((item) => {
    if (item.contract_address === contract) {
      itemToReturn = item;
    }
    return null;
  });
  return itemToReturn;
};

export const getCLIFFBalanceTransfers = (response) => {
  let balanceIN = 0;
  let balanceOUT = 0;

  response.data.data.data.items.map((item) => {
    balanceIN += parseFloat(
      item.transfers[0].transfer_type === "IN" ? formatUnits(item.transfers[0].delta, 9) : 0
    );

    balanceOUT += parseFloat(
      item.transfers[0].transfer_type === "OUT" ? formatUnits(item.transfers[0].delta, 9) : 0
    );

    return null;
  });

  return [parseFloat(balanceIN).toFixed(0), parseFloat(balanceOUT).toFixed(0)];
};

export const getCLIFFItem = (response) => {
  let itemToReturn;

  response.data.data.data.items.map((item) => {
    if (item.contract_address === "0x1B9BAF2A3EdeA91eE431f02d449a1044d5726669") {
      itemToReturn = item;
    }
    return null;
  });
  return itemToReturn;
};

export async function coingeckoCLIFF() {
  try {
    return await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=clifford-inu&vs_currencies=usd%2Ceur%2Cbtc%2Ceth`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}
