/**
=========================================================
* Clifford Inu
=========================================================
*/
function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = ownerState;

  const {
    white,
    transparent,
    dark,
    // grey,
    gradients,
    primary,
  } = palette;

  const { md } = boxShadows;
  const { borderRadius } = borders;
  const {
    pxToRem,
    // rgba,
    linearGradient,
  } = functions;

  let color = white.main;
  let backgroundColor = "";
  if (sidenavColor === "none" && active) {
    backgroundColor = gradients.primary.main;
  } else if ((transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)) {
    backgroundColor = dark.main;
  } else {
    backgroundColor = '';
  }
  return {
    backgroundColor,
    color,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(8)} ${pxToRem(10)}`,
    margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    marginBottom:"15px",
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active && !whiteSidenav && !darkMode && !transparentSidenav ? md : "none",
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

    "&:hover, &:focus": {
      backgroundColor: () => primary.main,

      /* {
           let backgroundValue;

        if (!active) {
          backgroundValue =
            transparentSidenav && !darkMode
              ? grey[300]
              : rgba(whiteSidenav ? grey[400] : white.main, 0.2);
        }
 
        return backgroundValue
      }, */
    },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, transitions, borders, functions } = theme;
  const { transparentSidenav, whiteSidenav, active, darkMode } = ownerState;

  const { white, dark, primary } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  let color = "";
  if (active) {
    color = primary.main;
  } else if ((transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)) {
    color = dark.main;
  } else {
    color = white.main;
  }
  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),
    "&:hover, &:focus": {
      color: () => primary.main,
    },
    "& svg, svg g": {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main,
    },
  };
}

function collapseIcon({
  palette: {
    // gradients,
    primary,
  },
}) {
  /* {
    active
  } */
  /*  let color = "";
  if (active) {
    color = primary.main;
  } else {
    color = gradients.dark.state;
  } */
  return {
    color: primary.main,
    "&:hover, &:focus": {
      color: () => primary.main,
    },
  };
}

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions, palette } = theme;
  const { miniSidenav, transparentSidenav } = ownerState;
  const { primary } = palette;

  const { size, fontWeightBold } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(10),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    "& span": {
      fontWeight: fontWeightBold,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText };
