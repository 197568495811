import { formatUnits } from "@ethersproject/units";

// Soft UI Dashboard React components
// import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDBadge from "components/MDBadge";

import axios from "axios";

// use moralis https://github.com/nft-api/nft-api#getnftlowestprice
axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";

const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 12,
});

export async function openSeaAssets(wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}` +
        `/portfolio_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    // api.opensea.io/api/v1/assets?owner=0x81ed317154e4c6e829b0358f59c5578719e95ccb&order_direction=desc&offset=0&limit=100

    return await axios.get(
      `https://api.opensea.io/api/v1/assets?owner=${wallet}&order_direction=desc&offset=0&limit=50&format=json`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function openSeaCollections(wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}` +
        `/portfolio_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    // api.opensea.io/api/v1/assets?owner=0x81ed317154e4c6e829b0358f59c5578719e95ccb&order_direction=desc&offset=0&limit=100

    return await axios.get(
      `https://api.opensea.io/api/v1/collections?asset_owner=${wallet}&format=json`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}
export async function openSeaCollectionStats(slug) {
  try {
    return await axios.get(`https://api.opensea.io/api/v1/collection/${slug}/stats?format=json`);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function balance(wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}` +
        `/portfolio_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/balances_v2/${wallet}/true`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function nftTransaction(contract, tokenId) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}` +
        `/portfolio_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/nft_transactions/${contract}/${tokenId}`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function getNFTPrice(contract, tokenId) {
  // optrional contract, get only price for this contract
  // optional bool, if true we count only contractFilter

  const response = await nftTransaction(contract, tokenId);
  let priceETH = 0;
  let priceUSD = 0;
  let txHash = "";

  for (let i = 0; i < response.data.data.data.items[0].nft_transactions.length; i += 1) {
    const nftT = response.data.data.data.items[0].nft_transactions[i];
    // response.data.data.data.items[0].nft_transactions.map((nftTransaction) => {
    if (nftT.value > 0) {
      priceETH += parseFloat(formatUnits(nftT.value, 18));
      priceUSD += parseFloat(nftT.value_quote);
      txHash = nftT.tx_hash;
      break;
      // parseFloat(item.holdings[0].close.quote || 0);
    }
  }

  return { priceETH, priceUSD, txHash };
}

export const getBalanceNFTNumber = (response) => {
  let number = 0;
  response.data.data.data.items.map((item) => {
    if (item.balance > 0 && item.type === "nft") {
      number += 1;
    }
    return null;
  });
  return number;
};
export async function getBalanceNFTDataTable(response, datatable, tokenHash) {
  let priceData = 0;
  await Promise.all(
    response.data.data.data.items.map(async (item) => {
      if (item.balance > 0 && item.type === "nft") {
        //
        let priceETH = 0;
        let priceUSD = 0;

        await Promise.all(
          item.nft_data.map(async (nftItem) => {
            const price = await getNFTPrice(item.contract_address, nftItem.token_id);

            if (!tokenHash.includes(price.txHash)) {
              priceETH = price.priceETH;
              priceUSD = price.priceUSD;
              priceData += price.priceUSD;
              tokenHash.push(price.txHash);
              datatable.push({
                asset: (
                  <MDTypography
                    component="div"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {`${item.contract_name} (${item.contract_ticker_symbol})`}
                  </MDTypography>
                ),
                tokenId: (
                  <MDTypography
                    component="div"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {nftItem.token_id}
                  </MDTypography>
                ),

                amountETH: (
                  <MDTypography
                    component="a"
                    href="#"
                    target="_blank"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {`${formatter.format(priceETH)}`}
                  </MDTypography>
                ),
                amountUSD: (
                  <MDTypography
                    component="div"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {formatterUS.format(parseFloat(priceUSD).toFixed(2))}
                  </MDTypography>
                ),
              });
            } else {
              priceETH = 0;
              priceUSD = 0;
            }

            return null;
          })
        );
      }
      return null;
    })
  );
  return priceData;
}

export async function getNFTPortfolioPrice(response, tokenHash) {
  let priceData = 0;
  await Promise.all(
    response.data.data.data.items.map(async (item) => {
      if (item.balance > 0 && item.type === "nft") {
        //
        //  let priceETH = 0;
        //  let priceUSD = 0;

        await Promise.all(
          item.nft_data.map(async (nftItem) => {
            const price = await getNFTPrice(item.contract_address, nftItem.token_id);

            if (!tokenHash.includes(price.txHash)) {
              // priceETH = price.priceETH;
              // priceUSD = price.priceUSD;
              priceData += price.priceUSD;
              tokenHash.push(price.txHash);
            } else {
              //   priceETH = 0;
              //   priceUSD = 0;
            }

            return null;
          })
        );
      }
      return null;
    })
  );
  return priceData;
}

export async function getOpenSeaAssetsPrice(response) {
  let priceData = 0;
  await Promise.all(
    response.data.assets.map(async (item) => {
      if (item.last_sale) {
        priceData += parseFloat(
          formatUnits(item.last_sale.total_price, 18) *
            item.last_sale.quantity *
            item.last_sale.payment_token.usd_price
        );
      }
      return null;
    })
  );
  return priceData;
}

export async function getOpenSeaCollectionsPrice(response, datatable, ethResponse) {
  let priceData = 0;
  await Promise.all(
    response.data.map(async (item) => {
      const getFloor = await openSeaCollectionStats(item.slug);
      priceData += parseFloat(item.owned_asset_count * getFloor.data.stats.floor_price);
      if (datatable) {
        datatable.push({
          asset: (
            <MDTypography
              component="div"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              {item.name}
            </MDTypography>
          ),
          balance: (
            <MDTypography
              component="div"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              {item.owned_asset_count}
            </MDTypography>
          ),

          amountETH: (
            <MDTypography
              component="a"
              href="#"
              target="_blank"
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              {`${formatter.format(getFloor.data.stats.floor_price * item.owned_asset_count)}`}
            </MDTypography>
          ),
          amountUSD: (
            <MDTypography
              component="div"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              {formatterUS.format(
                parseFloat(
                  getFloor.data.stats.floor_price *
                    item.owned_asset_count *
                    ethResponse.data.ethereum.usd
                ).toFixed(2)
              )}
            </MDTypography>
          ),
        });
      }
      return null;
    })
  );

  return priceData;
}

export function getOpenSeaCollecionsAssetsNumber(response) {
  let number = 0;
  response.data.map(async (item) => {
    number += item.owned_asset_count;
    return null;
  });

  return number;
}

export async function getOpenSeaAssetsTable(response, datatable) {
  response.data.assets.map(async (item) => {
    datatable.push({ image_thumbnail_url: item.image_thumbnail_url, permalink: item.permalink });
    return null;
  });
  return datatable;
}

export async function getOpenSeaCollectionsTable(response, datatable) {
  response.data.map(async (item) => {
    datatable.push({
      imageUrl: item.image_url,
      permalink: `https://opensea.io/collection/${item.slug}`,
    });
    return null;
  });
  return datatable;
}

export async function greedykiwi(wallet) {
  try {
    return await axios.get(`https://api.greedykiwi.com/api/v1/account/${wallet}/portfolio`);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function coingeckoETH() {
  try {
    return await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd%2Ceur%2Cbtc%2Ceth`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}
