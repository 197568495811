import { formatUnits } from "@ethersproject/units";

import axios from "axios";

axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";

const days = ["S", "M", "T", "W", "T", "F", "S"];
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function rotateArray(nums, k) {
  for (let i = 0; i < k; i += 1) {
    nums.unshift(nums.pop());
  }

  return nums;
}

export const stakingMapping = {
  // GLA as Staked ICE
  /*  "0x4b993a3544b01946c474bcb82fd483a5cff32a46": {
      contract: "0x9235b893a3e61a14b2d02a91ec1394fbf411689e",
      name: "ICE",
    }, */
};

// chain Id => https://www.covalenthq.com/docs/networks/avalanche
export async function balance(contract) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${contract}/` +
        `balances_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/balances_v2/${contract}/false`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function transfers(contract, wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}/` +
        `transfers_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3&contract-address=${contract}`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/transfers_v2/${wallet}/${contract}`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export async function portfolio(wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}` +
        `/portfolio_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/portfolio_v2/${wallet}`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export const getBalanceTransfers = (response) => {
  let balanceTransfers = 0;
  response.data.data.data.items.map((item) => {
    balanceTransfers += parseFloat(formatUnits(item.transfers[0].delta, 18));
    return null;
  });
  return balanceTransfers.toFixed(0);
};

export const getCliffordItem = (response, contract) => {
  let itemToReturn;

  response.data.data.data.items.map((item) => {
    if (item.contract_address === contract) {
      itemToReturn = item;
    }
    return null;
  });
  return itemToReturn;
};

export const getItemPortfolioPrice = (response, contract) => {
  let price = 0;
  response.data.data.data.items.map((item) => {
    if (item.contract_address === contract) {
      price += parseFloat(item.holdings[0].quote_rate ? item.holdings[0].quote_rate : 0);
    }
    return null;
  });
  return price;
};

export const getPortfolioPrice = (response, day) => {
  // optrional contract, get only price for this contract
  // optional bool, if true we count only contractFilter
  let price = 0;
  const back = day || 0;
  response.data.data.data.items.map((item) => {
    if (item.holdings[back].close.balance > 0) {
      const stakingPrice = 0;
      /*  if (stakingMapping[item.contract_address]) {
          stakingPrice = getItemPortfolioPrice(
            response,
            stakingMapping[item.contract_address].contract
          );
        } */
      price += parseFloat(
        stakingPrice !== 0
          ? stakingPrice * formatUnits(item.holdings[back].close.balance, item.contract_decimals)
          : item.holdings[back].close.quote || 0
      );
    }
    return null;
  });
  return price;
};

export const getPortfolioNumber = (response, contractFilter, bool) => {
  let number = 0;
  response.data.data.data.items.map((item) => {
    if (
      (contractFilter && bool ? item.contract_address === contractFilter : true) &&
      (contractFilter && !bool ? item.contract_address !== contractFilter : true) &&
      item.holdings[0].close.balance > 0
    ) {
      number += 1;
    }
    return null;
  });
  return number;
};

export const getPortfolioItemBalance = (response, contractFilter) => {
  let balanceToReturn = 0;
  response.data.data.data.items.map((item) => {
    if (
      (contractFilter ? item.contract_address === contractFilter : true) &&
      item.holdings[0].close.balance > 0
    ) {
      balanceToReturn += parseFloat(
        formatUnits(item.holdings[0].close.balance, item.contract_decimals)
      );
    }
    return null;
  });
  return balanceToReturn;
};

export const getPortfolioBalanceOneWeekHistory = (response, contractFilter, bool) => {
  // optrional contract, get only price for this contract
  // optional bool, if true we count only contractFilter

  const history = {
    labels: [],
    datasets: { label: "BuyBacks", data: [] },
  };

  response.data.data.data.items.map((item) => {
    if (
      (contractFilter && bool ? item.contract_address === contractFilter : true) &&
      (contractFilter && !bool ? item.contract_address !== contractFilter : true)
    ) {
      item.holdings
        .filter((holding, idx) => idx < 7) // Only one week
        .map((holding) => {
          const a = new Date(holding.timestamp);
          history.labels.push(days[a.getDay()]);
          history.datasets.data.push(
            parseFloat(formatUnits(holding.close.balance, item.contract_decimals))
          );
          return null;
        });
    }
    return null;
  });
  history.labels.reverse();
  history.datasets.data.reverse();
  return history;
};

export const getPortfolioPriceHistory = (response, maxDay) => {
  // optrional contract, get only price for this contract
  // optional bool, if true we count only contractFilter

  const history = {
    labels: [],
    datasets: { label: "Portfolio Value", data: [],tension:1 },
  };

  response.data.data.data.items.map((item) => {
    const historyDays = maxDay || 7;

    item.holdings
      .filter((holding, idx) => idx < historyDays) // Only one week
      .map((holding, idx) => {
        const a = new Date(holding.timestamp);
        history.labels[idx] = `${a.getUTCDate()} ${monthNames[a.getMonth()]}`;

        // history.labels[idx] = days[a.getDay()];
        history.datasets.data[idx] = history.datasets.data[idx] ? history.datasets.data[idx] : 0;
        history.datasets.data[idx] += parseFloat(holding.close.quote);
        return null;
      });

    return null;
  });
  history.labels.reverse();
  history.datasets.data.reverse();

  return history;
};

export const getTransfersOneWeekHistory = (response, withValue) => {
  const history = {
    labels: [],
    datasets: { label: "Daily Total", data: [0, 0, 0, 0, 0, 0, 0] },
  };
  const today = new Date();

  history.labels = rotateArray(days.slice(), 6 - today.getDay());

  for (let i = 0; i < response.data.data.data.items.length; i += 1) {
    const item = response.data.data.data.items[i];
    const a = new Date(item.block_signed_at);
    const difference = today - a;
    // console.log("difference ", Math.floor(difference / (24 * 3600 * 1000)));

    if (Math.floor(difference / (24 * 3600 * 1000)) > 6) {
      break;
    } else if (withValue) {
      if (item.value > 0) {
        history.datasets.data[a.getDay()] += parseFloat(formatUnits(item.transfers[0].delta, 18));
      }
    } else
      history.datasets.data[a.getDay()] += parseFloat(formatUnits(item.transfers[0].delta, 18));
  }

  history.datasets.data = rotateArray(history.datasets.data.slice(), today.getDay() + 1);

  // console.log("history", history);
  return history;
};

export const getBuyBacksPrice = (response) => {
  let price = 0;
  response.data.data.data.items.map((item) => {
    if (item.value > 0) {
      price += parseFloat(formatUnits(item.value, 18)) * item.gas_quote_rate;
    }
    return null;
  });
  return price;
};

export const getBuyBacksBalance = (response) => {
  let price = 0;
  response.data.data.data.items.map((item) => {
    if (item.value > 0) {
      price += parseFloat(formatUnits(item.balance, 18));
    }
    return null;
  });
  return price;
};

function compare(a, b) {
  if (a.currentPrice < b.currentPrice) {
    return 1;
  }
  if (a.currentPrice > b.currentPrice) {
    return -1;
  }
  return 0;
}

export const getTopPortfolio = (response) => {
  const topTable = [];
  response.data.data.data.items.map((item) => {
    // console.log(item);
    if (item.holdings[0].close.balance > 0) {
      topTable.push({
        currentPrice: item.holdings[0].close.quote,
        lastDayPrice:
          item.holdings[1] && item.holdings[1].close && item.holdings[1].close.quote
            ? item.holdings[1].close.quote
            : null,
        balance: parseFloat(
          formatUnits(item.holdings[0].close.balance, item.contract_decimals)
        ).toFixed(2),
        name: item.contract_name,
        symbol: item.contract_ticker_symbol,
        //  logo: `https://raw.githubusercontent.com/dappradar/tokens/main/ethereum/${item.contract_address}/logo.png`,
        // https://etherscan.io/token/images/_32.png`,
        // item.logo_url,
        address: item.contract_address,
      });
    }
    return null;
  });

  topTable.sort(compare);
  return topTable;
};
