import { formatUnits } from "@ethersproject/units";

// Soft UI Dashboard React components
// import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDBadge from "components/MDBadge";

import axios from "axios";

axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";

const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const days = ["S", "M", "T", "W", "T", "F", "S"];
function rotateArray(nums, k) {
  for (let i = 0; i < k; i += 1) {
    nums.unshift(nums.pop());
  }

  return nums;
}

export async function transfers(contract, wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}/` +
        `transfers_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3&contract-address=${contract}&page-size=1000`
    ); */

    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/transfers_v2/${wallet}/${contract}`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}
export const getBurns = (response) => response.data.data.data.items.length;

export const getBurnsPrice = (response) => {
  let price = 0;
  response.data.data.data.items.map((item) => {
    if (item.value > 0) {
      price += parseFloat(formatUnits(item.value, 18)) * item.gas_quote_rate;
    }
    return null;
  });
  return price;
};

export const getBurnsDataTable = (response, priceCliff) => {
  const datatable = [];
  response.data.data.data.items.map((item) => {
    const dateBuy = new Date(item.block_signed_at);
    datatable.push({
      date: (
        <MDTypography component="div" href="#" variant="caption" color="text" fontWeight="medium">
          {`${dateBuy.getUTCFullYear()}-${dateBuy.getUTCMonth() + 1}-${dateBuy.getUTCDate()}`}
        </MDTypography>
      ),
      amount: (
        <MDTypography component="div" href="#" variant="caption" color="text" fontWeight="medium">
          {formatter.format(parseFloat(formatUnits(item.transfers[0].delta, 18)).toFixed(0))}
        </MDTypography>
      ),
      amountUSD: (
        <MDTypography component="div" href="#" variant="caption" color="text" fontWeight="medium">
          {priceCliff
            ? formatterUS.format(
                parseFloat(formatUnits(item.transfers[0].delta, 18) * priceCliff).toFixed(0)
              )
            : formatterUS.format(item.transfers[0].delta_quote)}
        </MDTypography>
      ),
      txHash: (
        <MDTypography
          component="a"
          href={`https://etherscan.io/tx/${item.tx_hash}`}
          target="_blank"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {item.tx_hash}
        </MDTypography>
      ),
    });

    return null;
  });

  return datatable;
};

export async function balance(contract) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${contract}/` +
        `balances_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/balances_v2/${contract}/false`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export const getBalanceTransfers = (response) => {
  let balanceTransfers = 0;
  response.data.data.data.items.map((item) => {
    balanceTransfers += parseFloat(formatUnits(item.transfers[0].delta, 18));
    return null;
  });
  return balanceTransfers.toFixed(0);
};

export const getBalanceTransfersToday = (response) => {
  let balanceTransfersToday = 0;
  const today = new Date();

  response.data.data.data.items.map((item) => {
    const date = new Date(item.block_signed_at);

    if (
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    )
      balanceTransfersToday += parseFloat(formatUnits(item.transfers[0].delta, 18));
    return null;
  });
  return balanceTransfersToday.toFixed(0);
};

export const getCliffordItem = (response, contract) => {
  let itemToReturn;
  response.data.data.data.items.map((item) => {
    if (item.contract_address === contract) {
      itemToReturn = item;
    }
    return null;
  });
  return itemToReturn;
};

export const getTransfersOneWeekHistory = (response, withValue) => {
  const history = {
    labels: [],
    datasets: { label: "Daily Total", data: [0, 0, 0, 0, 0, 0, 0] },
  };
  const today = new Date();

  history.labels = rotateArray(days.slice(), 7 - today.getDay());

  for (let i = 0; i < response.data.data.data.items.length; i += 1) {
    const item = response.data.data.data.items[i];
    // console.log("item ", item);

    const a = new Date(item.block_signed_at);
    const difference = today - a;
    // console.log("difference ", Math.floor(difference / (24 * 3600 * 1000)));

    if (Math.floor(difference / (24 * 3600 * 1000)) > 6) {
      break;
    } else if (withValue) {
      if (item.value > 0) {
        history.datasets.data[a.getDay()] += parseFloat(formatUnits(item.transfers[0].delta, 18));
      }
    } else
      history.datasets.data[a.getDay()] += parseFloat(formatUnits(item.transfers[0].delta, 18));
  }

  history.datasets.data = rotateArray(history.datasets.data.slice(), today.getDay() + 1);

  // console.log("history", history);
  return history;
};
