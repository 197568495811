/**
=========================================================
* Clifford Inu
=========================================================
*/

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};
