/**
=========================================================
* Clifford Inu
=========================================================
*/

/**
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Buybacks from "layouts/buybacks";
import Burns from "layouts/burns";
import Portfolio from "layouts/portfolio";

import MarketingWallet from "./layouts/marketing";
import NFTWallet from "./layouts/nft";
import Claims from "layouts/claims";

// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

const routes = [
  {
    type: "collapse",
    name: "Portfolio",
    key: "portfolio",
    icon: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.43189 18C5.68427 18 5.93007 18.0001 6.13822 17.9831C6.36682 17.9644 6.63655 17.9203 6.90798 17.782C7.2843 17.5903 7.59026 17.2843 7.78201 16.908C7.92031 16.6366 7.96437 16.3668 7.98305 16.1382C8.00006 15.9301 8.00003 15.6843 8 15.4319V12.5681C8.00003 12.3157 8.00006 12.0699 7.98305 11.8618C7.96437 11.6332 7.92031 11.3634 7.78201 11.092C7.59026 10.7157 7.2843 10.4097 6.90798 10.218C6.63655 10.0797 6.36682 10.0356 6.13822 10.0169C5.93008 9.99994 5.6843 9.99997 5.43193 10H2.5681C2.31573 9.99997 2.06991 9.99994 1.86176 10.0169C1.63317 10.0356 1.36344 10.0797 1.09201 10.218C0.715691 10.4097 0.409729 10.7157 0.217981 11.092C0.0796852 11.3634 0.0356178 11.6332 0.0169411 11.8618C-6.67572e-05 12.0699 -3.8147e-05 12.3157 -7.62939e-06 12.5681V15.4319C-3.8147e-05 15.6843 -6.67572e-05 15.9301 0.0169411 16.1382C0.0356178 16.3668 0.0796852 16.6366 0.217981 16.908C0.409729 17.2843 0.715691 17.5903 1.09201 17.782C1.36344 17.9203 1.63317 17.9644 1.86176 17.9831C2.06992 18.0001 2.3157 18 2.56808 18L5.43189 18Z" fill="#7B818C"/>
            <path d="M15.4319 8C15.6843 8.00004 15.9301 8.00006 16.1382 7.98306C16.3668 7.96438 16.6366 7.92031 16.908 7.78202C17.2843 7.59027 17.5903 7.28431 17.782 6.90798C17.9203 6.63656 17.9644 6.36683 17.983 6.13823C18.0001 5.93008 18 5.68429 18 5.43191L18 2.5681C18 2.31573 18.0001 2.06993 17.983 1.86177C17.9644 1.63318 17.9203 1.36345 17.782 1.09202C17.5903 0.715698 17.2843 0.409737 16.908 0.217989C16.6366 0.0796928 16.3668 0.0356255 16.1382 0.0169487C15.9301 -5.91278e-05 15.6843 -3.05176e-05 15.4319 0H12.5681C12.3157 -3.05176e-05 12.0699 -5.91278e-05 11.8618 0.0169487C11.6332 0.0356255 11.3634 0.0796928 11.092 0.217989C10.7157 0.409737 10.4097 0.715698 10.218 1.09202C10.0797 1.36345 10.0356 1.63318 10.0169 1.86177C9.99993 2.06992 9.99996 2.3157 9.99999 2.56807V5.4319C9.99996 5.68427 9.99993 5.93008 10.0169 6.13823C10.0356 6.36683 10.0797 6.63656 10.218 6.90798C10.4097 7.28431 10.7157 7.59027 11.092 7.78202C11.3634 7.92031 11.6332 7.96438 11.8618 7.98306C12.0699 8.00006 12.3157 8.00004 12.5681 8H15.4319Z" fill="#7B818C"/>
            <path d="M5.43189 8C5.68428 8.00004 5.93007 8.00006 6.13822 7.98306C6.36682 7.96438 6.63655 7.92031 6.90798 7.78202C7.2843 7.59027 7.59026 7.28431 7.78201 6.90798C7.92031 6.63656 7.96437 6.36683 7.98305 6.13823C8.00006 5.93007 8.00003 5.68428 8 5.4319V2.5681C8.00003 2.31572 8.00006 2.06993 7.98305 1.86177C7.96437 1.63318 7.92031 1.36345 7.78201 1.09202C7.59026 0.715698 7.2843 0.409737 6.90798 0.217989C6.63655 0.0796928 6.36682 0.0356255 6.13822 0.0169487C5.93007 -5.91278e-05 5.68427 -3.05176e-05 5.43189 0H2.5681C2.31571 -3.05176e-05 2.06992 -5.91278e-05 1.86176 0.0169487C1.63317 0.0356255 1.36344 0.0796928 1.09201 0.217989C0.715691 0.409737 0.409729 0.715698 0.217981 1.09202C0.0796852 1.36345 0.0356178 1.63318 0.0169411 1.86177C-6.67572e-05 2.06993 -3.8147e-05 2.31572 -7.62939e-06 2.5681V5.4319C-3.8147e-05 5.68428 -6.67572e-05 5.93007 0.0169411 6.13823C0.0356178 6.36683 0.0796852 6.63656 0.217981 6.90798C0.409729 7.28431 0.715691 7.59027 1.09201 7.78202C1.36344 7.92031 1.63317 7.96438 1.86176 7.98306C2.06992 8.00006 2.31571 8.00004 2.5681 8H5.43189Z" fill="#7B818C"/>
            <path d="M15.4319 18C15.6843 18 15.9301 18.0001 16.1382 17.9831C16.3668 17.9644 16.6366 17.9203 16.908 17.782C17.2843 17.5903 17.5903 17.2843 17.782 16.908C17.9203 16.6366 17.9644 16.3668 17.983 16.1382C18.0001 15.9301 18 15.6843 18 15.4319L18 12.5681C18 12.3157 18.0001 12.0699 17.983 11.8618C17.9644 11.6332 17.9203 11.3634 17.782 11.092C17.5903 10.7157 17.2843 10.4097 16.908 10.218C16.6366 10.0797 16.3668 10.0356 16.1382 10.0169C15.9301 9.99994 15.6843 9.99997 15.4319 10H12.5681C12.3157 9.99997 12.0699 9.99994 11.8618 10.0169C11.6332 10.0356 11.3634 10.0797 11.092 10.218C10.7157 10.4097 10.4097 10.7157 10.218 11.092C10.0797 11.3634 10.0356 11.6332 10.0169 11.8618C9.99993 12.0699 9.99996 12.3157 9.99999 12.5681V15.4319C9.99996 15.6843 9.99993 15.9301 10.0169 16.1382C10.0356 16.3668 10.0797 16.6366 10.218 16.908C10.4097 17.2843 10.7157 17.5903 11.092 17.782C11.3634 17.9203 11.6332 17.9644 11.8618 17.9831C12.0699 18.0001 12.3157 18 12.5681 18L15.4319 18Z" fill="#FFFDF5" fill-opacity="0.6"/>
          </svg>,
    route: "/portfolio",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tokens",
    key: "tokens",
    route: "/tokens",
    icon: <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M5 6.99995C5 10.5898 7.91015 13.5 11.5 13.5C15.0899 13.5 18 10.5898 18 6.99995C18 3.4101 15.0899 0.499954 11.5 0.499954C7.91015 0.499954 5 3.4101 5 6.99995Z" fill="#7B818C"/>
    <path d="M0 12C0 15.5898 2.91015 18.5 6.5 18.5C10.0899 18.5 13 15.5898 13 12C13 8.4101 10.0899 5.49995 6.5 5.49995C2.91015 5.49995 0 8.4101 0 12Z" fill="#7B818C"/>
    </svg>,    
    component: <MarketingWallet />,
  },
  {
    type: "collapse",
    name: "NFTs",
    key: "nfts",
    route: "/nfts",
    icon: <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.75537 0V14.8326L0 11.0215L8.75537 0Z" fill="#7B818C"/>
            <path opacity="0.6" d="M17.5102 11.0215L8.75488 14.8326V0L17.5102 11.0215Z" fill="#7B818C"/>
            <path opacity="0.6" d="M17.5102 14.5236L8.75488 24V18.2317L17.5102 14.5236Z" fill="#7B818C"/>
            <path d="M8.75537 18.2317V24L0 14.5236L8.75537 18.2317Z" fill="#7B818C"/>
          </svg>,    
    component: <NFTWallet />,
  },
  {
    type: "collapse",
    name: "Burns",
    key: "burns",
    icon: <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0177 23.9624C7.1228 23.9624 7.20747 24.047 5.93738 23.9624C4.41327 23.793 2.29646 23.1156 1.02637 21.0835C1.36506 21.2528 4.49795 23.1156 7.46149 19.3054C10.2557 15.7491 7.46149 12.4469 7.29214 12.1929C7.20747 11.9388 7.03813 11.7695 6.95345 11.5155C6.36075 10.6688 5.51402 8.9753 5.34467 7.36652C5.17533 4.57232 6.44542 1.60878 9.32429 0C9.23962 0.254018 7.46149 4.23363 11.2718 7.87456C15.336 11.7695 16.6908 14.1403 16.8602 17.0192C17.0295 19.8981 14.9974 23.9624 11.0177 23.9624Z" fill="#7B818C"/>
    <path opacity="0.6" d="M7.54637 19.3054C4.58283 23.1156 1.44994 21.2528 1.11125 21.0835C0.603215 20.4061 0.264524 19.4747 0.095179 18.4586C-0.666875 14.225 3.39741 10.4994 3.39741 10.4994C3.39741 10.4994 2.212 15.7491 5.42956 16.0031C7.20768 16.1725 8.13908 14.1403 7.37703 12.1929C7.54637 12.4469 10.3406 15.7491 7.54637 19.3054Z" fill="#7B818C"/>
    </svg>,
    route: "/burns",
    component: <Burns />,
  },
  {
    type: "collapse",
    name: "Stake",
    key: "stake",
    icon: <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M0.27 2.38135C0.09 2.83135 0 3.28135 0 4.09135C0 4.36135 0.18 4.54135 0.45 4.54135C2.07 4.27135 3.33 3.01135 3.6 1.39135C3.6 1.12135 3.42 0.941345 3.15 0.941345C2.34 0.941345 1.89 1.03135 1.44 1.21135C0.99 1.48135 0.54 1.93135 0.27 2.38135Z" fill="#7B818C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.55 6.34134C14.94 6.16135 12.87 4.00135 12.6 1.39135C12.6 1.12135 12.42 0.941345 12.15 0.941345H5.85C5.58 0.941345 5.4 1.12135 5.4 1.39135C5.13 4.00135 3.06 6.07134 0.45 6.34134C0.18 6.34134 0 6.52135 0 6.79135V9.49135C0 9.76134 0.18 9.94135 0.45 9.94135C3.06 10.1213 5.13 12.2813 5.4 14.8913C5.4 15.1613 5.58 15.3413 5.85 15.3413H12.15C12.42 15.3413 12.6 15.1613 12.6 14.8913C12.78 12.2813 14.94 10.2113 17.55 9.94135C17.82 9.94135 18 9.76134 18 9.49135V6.79135C18 6.52135 17.82 6.34134 17.55 6.34134ZM9 9.94135C8.01 9.94135 7.2 9.13134 7.2 8.14135C7.2 7.15135 8.01 6.34134 9 6.34134C9.99 6.34134 10.8 7.15135 10.8 8.14135C10.8 9.13134 9.99 9.94135 9 9.94135Z" fill="#7B818C"/>
            <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M14.4004 14.8913C14.4004 15.1613 14.5804 15.3413 14.8504 15.3413C15.6604 15.3413 16.1104 15.2513 16.5604 15.0713C17.1004 14.8013 17.4604 14.4413 17.7304 13.9013C17.9104 13.5413 18.0004 13.0013 18.0004 12.1913C18.0004 11.9213 17.8204 11.7413 17.5504 11.7413C15.9304 12.0113 14.6704 13.2713 14.4004 14.8913Z" fill="#7B818C"/>
            <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M0 12.1913C0 11.9213 0.18 11.7413 0.45 11.7413C2.07 11.9213 3.33 13.2713 3.6 14.8913C3.6 15.1613 3.42 15.3413 3.15 15.3413C2.34 15.3413 1.89 15.2513 1.44 15.0713C0.9 14.8013 0.54 14.4413 0.27 13.9013C0.09 13.4513 0 13.0013 0 12.1913Z" fill="#7B818C"/>
            <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M17.5504 4.54135C17.8204 4.54135 18.0004 4.36135 18.0004 4.09135C18.0004 3.28135 17.9104 2.83135 17.7304 2.38135C17.4604 1.84135 17.1004 1.48135 16.5604 1.21135C16.2004 1.03135 15.6604 0.941345 14.8504 0.941345C14.5804 0.941345 14.4004 1.12135 14.4004 1.39135C14.6704 3.01135 15.9304 4.27135 17.5504 4.54135Z" fill="#7B818C"/>
          </svg>,
    route: "/stake",
    component: <Portfolio />,
  },
  {
    type: "collapse",
    name: "Claim",
    key: "claim",
    icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.36328 20.6617V15.9476C6.36328 15.5464 6.56531 15.1452 6.96937 15.0449C7.57546 14.744 8.58561 14.3428 9.19169 14.3428C10.2018 14.3428 12.5252 15.5464 14.3434 16.6497C15.1516 17.1512 15.6566 17.4521 15.9597 17.6527C16.2627 17.8533 16.3637 18.2545 16.1617 18.6557C15.9597 18.9566 15.5556 19.0569 15.1516 18.8563L12.5252 17.4521C12.3232 17.3518 12.1211 17.4521 11.9191 17.5524C11.8181 17.753 11.8181 18.0539 12.0201 18.1542C12.6262 18.5554 13.9394 19.2575 14.3434 19.5584C14.9495 19.9596 16.0607 20.0599 16.6668 19.3578C16.8688 19.0569 16.9698 18.756 17.0708 18.5554C17.1719 18.2545 17.3739 17.9536 17.6769 17.8533C18.7881 17.4521 21.0104 16.6497 21.5155 16.4491C22.3236 16.1482 23.4348 15.8473 23.8388 16.75C24.2429 17.6527 23.8388 18.4551 23.1317 18.8563C22.4246 19.2575 17.3739 22.0659 16.1617 22.6677C14.9495 23.2695 13.4343 24.1722 12.1211 23.9716C11.111 23.8713 9.09068 21.5644 8.08053 21.6647C7.8785 21.6647 7.67647 21.6647 7.47444 21.6647C6.86835 21.765 6.36328 21.2635 6.36328 20.6617Z" fill="#7B818C"/>
    <path opacity="0.6" d="M4.14161 14.4429H1.11116C0.505074 14.4429 0 14.9444 0 15.5462V21.3636C0 21.9654 0.505074 22.4669 1.11116 22.4669H4.14161C4.74769 22.4669 5.25277 21.9654 5.25277 21.3636V15.5462C5.35378 14.9444 4.74769 14.4429 4.14161 14.4429Z" fill="#7B818C"/>
    <path d="M16.7683 10.2306L22.8292 7.62279L16.7683 0L10.6064 7.62279L16.7683 10.2306Z" fill="#7B818C"/>
    <path opacity="0.6" d="M16.7683 15.5467L22.8292 7.62305L16.7683 10.2308L10.6064 7.62305L16.7683 15.5467Z" fill="#7B818C"/>
    </svg>,
    route: "/claim",
    component: <Claims />,
  },
  // {
  //   type: "collapse",
  //   name: "RAFFLE",
  //   key: "raffle",
  //   icon: <Icon fontSize="small">adjust</Icon>,
  //   route: "/raffle",
  //   component: <Raffle />,
  // },
  /* {
    type: "collapse",
    name: "BuyBacks",
    key: "buybacks",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/buybacks",
    component: <Buybacks />,
  }, */
  /*  {
    type: "collapse",
    name: "Marketing Wallet",
    key: "marketingWallet",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/marketing",
    component: <MarketingWallet />,
  }, */
  /*  {
    type: "collapse",
    name: "NFT Portfolio",
    key: "nftPortfolio",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/nft",
    component: <NFTWallet />,
  }, */

  /* {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  }, */
];

export default routes;
