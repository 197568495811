/**
=========================================================
* Clifford Inu
=========================================================
*/
import React, { useState, useEffect } from "react";
import './styles.scss'
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
// utils
import {
  transfers,
  getBurnsDataTable,
  getCliffordItem,
  balance,
  getBalanceTransfers,
  getBalanceTransfersToday,
  getBurns,
  getTransfersOneWeekHistory,
} from "./utils";

import { coingeckoCLIFF } from "../portfolio/utils";

// Contracts
const cliffordContract = "0x1b9baf2a3edea91ee431f02d449a1044d5726669";
const deadWallet = "0x000000000000000000000000000000000000dead";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function Burns() {
  const { columns } = {
    columns: [
      { Header: "Date", accessor: "date", align: "left" },
      { Header: "Number $CLIFF", accessor: "amount", align: "left" },
      { Header: "Amount ($USDT)", accessor: "amountUSD", align: "left" },
      { Header: "Tx Hash", accessor: "txHash", align: "center" },
    ],
    // rows: [],
  };
  const [burnsLoader, setBurnsLoader] = useState(0);

  const [price, setPrice] = useState(0);
  const [priceLoader, setPriceLoader] = useState(0);
  const [deadSupply, setDeadSupply] = useState(0);
  const [deadSupplyToday, setDeadSupplyToday] = useState(0);
  const [numberBurns, setNumberBurns] = useState(0);

  const [datatableData, setDatatableData] = useState([]);

  const [deadSupplyHistory, setDeadSupplyHistory] = useState({});

  useEffect(() => {
    const fetchTransfers = async (contract, wallet) => {
      setBurnsLoader(1);
      // setDatatableData([]);
      try {
        const response = await transfers(contract, wallet);
        const cliffResponse = await coingeckoCLIFF();
        const priceCliff = cliffResponse.data["clifford-inu"].usd;
        const datatable = getBurnsDataTable(response, priceCliff);
        setDatatableData(datatable);
        const balanceDead = getBalanceTransfers(response);
        setDeadSupply(balanceDead);
        const balanceDeadToday = getBalanceTransfersToday(response);
        setDeadSupplyToday(balanceDeadToday);
        const burnsNumber = getBurns(response);
        setNumberBurns(burnsNumber);

        const historyBalanceDead = getTransfersOneWeekHistory(response, false);
        setDeadSupplyHistory(historyBalanceDead);
        // if (burnsLoader && datatableData && false) console.log(datatable);
        // console.log(datatable);
        // localStorage.setItem("burnDatatable", JSON.stringify(datatable));
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setBurnsLoader(0);
    };

    const fetchPrice = async (contract) => {
      setPriceLoader(1);
      try {
        const responsePrice = await balance(contract);

        const itemPrice = getCliffordItem(responsePrice, cliffordContract);
        setPrice(itemPrice.quote_rate);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setPriceLoader(0);
    };

    const refresh = async () => {
      await Promise.all([
        fetchPrice(cliffordContract),
        fetchTransfers(cliffordContract, deadWallet),
      ]);
    };

    refresh();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                // icon="balance"
                title="Total $CLIFF Burned"
                count={
                  !burnsLoader ? (
                    <div>{formatter.format(deadSupply)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
                percentage={
                  !burnsLoader
                    ? {
                        color: "success",
                        amount: deadSupply > 0 ? `+${formatter.format(deadSupplyToday)}` : 0,
                        label: `Burned Today`,
                      }
                    : {}
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                // icon="fireplace"
                color="warning"
                title="Total $USD Burned"
                count={
                  !priceLoader ? (
                    <div>{formatterUS.format(price * deadSupply)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
                percentage={
                  !priceLoader
                    ? {
                        color: "warning",
                        amount: `${formatter.format(
                          parseFloat((deadSupply / 1000000000000) * 100).toFixed(2)
                        )}%`,
                        label: "($CLIFF) Burned",
                      }
                    : {}
                }
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                // icon="numbers"
                title="Number of Burns"
                count={
                  !burnsLoader ? (
                    <div>{formatter.format(numberBurns)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
                   percentage={
                   {
                        color: "success",
                        amount: ``,
                        label: "-",
                      }
                    
                } 
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                // icon="paid"
                title="Average Burn ($USD)"
                count={
                  !burnsLoader && !priceLoader ? (
                    <div>{formatterUS.format((price * deadSupply) / numberBurns)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
                percentage={
                  {
                       color: "success",
                       amount: ``,
                       label: "-",
                     }
                   
               } 
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={1}>
              <ReportsBarChart
                color="dark"
                title="Daily Burns (CLIFF)"
                icon={(
                  <svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9355 36.935C6.03933 36.935 2.14827 30.8073 0.524438 25.752C-1.09939 20.6967 1.56614 15.7026 1.56614 15.7026L5.8555 19.8082C5.8555 19.8082 10.4512 24.4345 12.4121 26.6099C14.4036 28.8158 16.6708 33.3196 15.9355 36.935Z" fill="url(#paint0_linear_236_799)"/>
<path d="M22.8287 30.8995C22.5529 33.4118 21.2968 35.8016 21.2049 35.9548C19.6423 36.5982 17.8959 36.9352 15.9351 36.9352C16.6704 33.2893 14.4032 28.7854 12.4423 26.6101C10.4508 24.4348 5.88572 19.8084 5.88572 19.8084C5.79381 19.6859 4.56828 17.388 4.66019 14.6918C4.84402 9.97355 7.93849 8.0127 7.93849 8.0127L12.9325 12.7923C12.9325 12.7923 17.5283 16.8365 20.1019 20.2986C22.6142 23.822 23.1351 28.1114 22.8287 30.8995Z" fill="url(#paint1_linear_236_799)"/>
<path d="M21.2057 35.9546C21.2976 35.8014 22.5538 33.4423 22.8295 30.8993C23.1359 28.1112 22.615 23.8218 20.0721 20.3597C17.4985 16.867 12.9027 12.8227 12.9027 12.8227C9.37931 5.00995 15.078 0.935059 15.078 0.935059C17.1308 3.75378 28.5895 12.2406 29.6925 19.1342C30.7036 25.3844 27.8848 33.2278 21.2057 35.9546Z" fill="url(#paint2_linear_236_799)"/>
<defs>
<linearGradient id="paint0_linear_236_799" x1="0.160394" y1="17.8488" x2="13.6215" y2="37.1213" gradientUnits="userSpaceOnUse">
<stop stop-color="#813075"/>
<stop offset="1" stop-color="#FA3F41"/>
</linearGradient>
<linearGradient id="paint1_linear_236_799" x1="8.13944" y1="8.94171" x2="20.5924" y2="36.8522" gradientUnits="userSpaceOnUse">
<stop stop-color="#C43056"/>
<stop offset="1" stop-color="#FD8E55"/>
</linearGradient>
<linearGradient id="paint2_linear_236_799" x1="18.1338" y1="0.828499" x2="24.7754" y2="34.5108" gradientUnits="userSpaceOnUse">
<stop stop-color="#F73C41"/>
<stop offset="1" stop-color="#FFB262"/>
</linearGradient>
</defs>
</svg>
                )}
                description=""
                date="just updated"
                chart={deadSupplyHistory}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="burnTable">
              <MDBox
                mx={2}
                mt={1}
                py={1}
                px={2}
                
                borderRadius="lg"
              >
                <MDTypography variant="h6" color="white">
                  Burns
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {!burnsLoader ? (
                  <DataTable
                    table={{ columns, rows: datatableData }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <CircularProgress size={26} />
                )}
              </MDBox>
            </Card>
          </Grid>

          {/*   <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Burns;
