/**
=========================================================
* Cliff Inu
=========================================================
*/

import React from "react"; // useState,
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setOpenConfigurator } from "context";

import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { CircularProgress, Grid } from "@material-ui/core";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { useEthers } from "@usedapp/core";

import {
  // Web3ReactProvider,
  // useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";

import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
// import { Web3Provider } from "@ethersproject/providers";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { formatEther } from "@ethersproject/units";
import { useEagerConnect, useInactiveListener } from "./hooks";
import {
  injected,
  walletlink,
  // walletconnect
} from "./connectors";

const connectorsByName = {
  Metamask: injected,
  CoinBase: walletlink,
  // WalletConnect: walletconnect,
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  }
  if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  }
  if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return "Please authorize this website to access your Ethereum account.";
  }
  console.error(error);
  return (
    <Grid container width="100px">
      <Grid item>
        <MDTypography variant="info">Error occurred</MDTypography>
      </Grid>
      <Grid item>
        <MDTypography variant="info">{error.message}</MDTypography>
      </Grid>
    </Grid>
  );
}

/* function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
 */
/* function ChainId() {
  const { chainId } = useEthers();

  return (
    <>
      <span>Chain Id</span>
      <span role="img" aria-label="chain">
        ⛓
      </span>
      <span>{chainId ?? ""}</span>
    </>
  );
}

function BlockNumber() {
  const { chainId, library } = useEthers();

  const [blockNumber, setBlockNumber] = React.useState();
  React.useEffect(() => {
    // eslint-disable-next-line
    if (!!library) {
      let stale = false;

      library
        .getBlockNumber()
        .then((blockNumber2) => {
          if (!stale) {
            setBlockNumber(blockNumber2);
          }
        })
        .catch(() => {
          if (!stale) {
            setBlockNumber(null);
          }
        });

      const updateBlockNumber = (blockNumber2) => {
        setBlockNumber(blockNumber2);
      };
      library.on("block", updateBlockNumber);

      return () => {
        stale = true;
        library.removeListener("block", updateBlockNumber);
        setBlockNumber(undefined);
      };
    }
    return null;
  }, [library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

  return (
    <>
      <span>Block Number</span>
      <span role="img" aria-label="numbers">
        🔢
      </span>
      <span>{blockNumber === null ? "Error" : blockNumber ?? ""}</span>
    </>
  );
} */

function Account() {
  const { account } = useEthers();

  let printAccount = "-";
  if (account === null) {
    printAccount = "-";
  } else if (account) {
    printAccount = `${account.substring(0, 6)}...${account.substring(account.length - 4)}`;
  } else {
    printAccount = "";
  }
  return printAccount ? (
    <MDTypography variant="info" style={{ textAlign: "left" }}>
      <span role="img" aria-label="robot">
        🤖
      </span>
      <span>{printAccount}</span>
    </MDTypography>
  ) : (
    <></>
  );
}

function Balance() {
  const { account, library, chainId } = useEthers();

  const [balance, setBalance] = React.useState();
  React.useEffect(() => {
    if (!!account && !!library) {
      let stale = false;

      library
        .getBalance(account)
        .then((balance2) => {
          if (!stale) {
            setBalance(balance2);
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(null);
          }
        });

      return () => {
        stale = true;
        setBalance(undefined);
      };
    }
    return null;
  }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

  return balance ? (
    <MDTypography variant="info" style={{ textAlign: "left" }}>
      <span role="img" aria-label="gold">
        💰
      </span>
      <span>
        {balance === null
          ? "Error"
          : `Ξ${balance ? parseFloat(formatEther(balance)).toFixed(5) : 0}`}
      </span>
    </MDTypography>
  ) : (
    <></>
  );
}
function Header() {
  const { active, error } = useEthers();

  let ic = "🟠";
  if (active) ic = "🟢";
  if (error) ic = "🔴";
  return (
    <>
      <MDTypography variant="h3" style={{ textAlign: "left" }}>
        Status {ic}
      </MDTypography>

      {/*       <ChainId />
        <BlockNumber /> */}
      <Account />
      <Balance />
    </>
  );
}
function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,

    darkMode,
  } = controller;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const context = useEthers();
  const { connector, activate, deactivate, active, error, account } = context;
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Clifford Connect</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        <Header />
        <Divider />
        <MDTypography variant="h6">Connect to your Wallet:</MDTypography>
        <Grid container spacing={3}>
          {Object.keys(connectorsByName).map((name) => {
            const currentConnector = connectorsByName[name];
            const activating = currentConnector === activatingConnector;
            const connected = currentConnector === connector;
            const disabled = !triedEager || !!activatingConnector || connected || !!error;

            let borderColor = "unset";
            if (activating) {
              borderColor = "orange";
            }
            if (connected) {
              borderColor = "green";
            }
            return (
              <Grid item>
                <MDButton
                  style={{
                    height: "3rem",
                    borderRadius: "1rem",
                    //  borderColor: activating ? "orange" : connected ? "green" : "unset",
                    borderColor,

                    cursor: disabled ? "unset" : "pointer",
                    position: "relative",
                  }}
                  color="primary"
                  disabled={disabled}
                  key={name}
                  onClick={async () => {
                    setActivatingConnector(currentConnector);

                    await activate(connectorsByName[name]);

                    /*    await activate(walletconnect, undefined, true).catch((errore) => {
                      if (errore instanceof UnsupportedChainIdError) {
                        activate(walletconnect);
                      } else {
                        console.log(errore);
                        console.log("Pending Error Occured");
                      }
                    }); */
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                      margin: "0 0 0 1rem",
                    }}
                  >
                    {activating && (
                      <CircularProgress
                        color="primary"
                        style={{ height: "25%", marginLeft: "-1rem" }}
                      />
                    )}
                    {connected && account && (
                      <span role="img" aria-label="check">
                        ✅
                      </span>
                    )}
                  </div>
                  {name}
                </MDButton>
              </Grid>
            );
          })}
        </Grid>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {(active || error) && (
            <MDButton
              style={{
                height: "3rem",
                marginTop: "2rem",
                borderRadius: "1rem",
                borderColor: "red",
                cursor: "pointer",
              }}
              onClick={() => {
                deactivate();
              }}
            >
              Disconnect
            </MDButton>
          )}

          {!!error && (
            <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>{getErrorMessage(error)}</h4>
          )}
        </div>
        <Divider />
        <div
          style={{
            display: "grid",
            gridGap: "1rem",
            gridTemplateColumns: "fit-content",
            maxWidth: "20rem",
            margin: "auto",
          }}
        >
          {/*    {connector === connectorsByName.WalletConnect && (
            <MDButton
              style={{
                height: "3rem",
                borderRadius: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                connector.close();
              }}
            >
              Kill WalletConnect Session
            </MDButton>
          )} */}
          {connector === connectorsByName.CoinBase && (
            <MDButton
              style={{
                height: "3rem",
                borderRadius: "1rem",
                cursor: "pointer",
              }}
              onClick={() => {
                connector.close();
              }}
            >
              Kill CoinBase Session
            </MDButton>
          )}
        </div>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default function () {
  return (
    /*  <Web3ReactProvider getLibrary={getLibrary}> */
    <Configurator />
    /*  </Web3ReactProvider> */
  );
}
