/**
=========================================================
* Clifford Inu
=========================================================
*/
import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import './styles.scss'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
// utils
// import { balance, getBalanceNFTDataTable } from "./utils";
import {
  // greedykiwi,
  coingeckoETH,
  openSeaCollections,
  getOpenSeaCollectionsPrice,
  getOpenSeaCollecionsAssetsNumber,
} from "./utils";

// Contracts
const tokensWallet = "0x20c606fbb6984aaab0cad48f1f2644693adc1680";
const deployerWallet = "0x81ed317154e4c6e829b0358f59c5578719e95ccb";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function NFTWallet() {
  const { columns } = {
    columns: [
      { Header: "Collection", accessor: "asset", align: "left" },
      { Header: "Balance", accessor: "balance", align: "left" },
      { Header: "Floor Value (ETH)", accessor: "amountETH", align: "left" },
      { Header: "Floor Value ($USD)", accessor: "amountUSD", align: "left" },
    ],
    // rows: [],
  };

  const [price, setPrice] = useState(0);
  const [priceLoader, setPriceLoader] = useState(0);
  // const [deadSupply, setDeadSupply] = useState(0);
  //  const [deadSupplyToday, setDeadSupplyToday] = useState(0);
  const [numberAssets, setNumberAssets] = useState(0);

  const [datatableData, setDatatableData] = useState([]);

  useEffect(() => {
    const fetchNFTPortfolio = async () => {
      setPriceLoader(1);
      setDatatableData([]);
      try {
        const datatable = [];

        const ethResponse = await coingeckoETH();
        const response = await openSeaCollections(tokensWallet);
        const responseNFT = await openSeaCollections(deployerWallet);
        let priceP = await getOpenSeaCollectionsPrice(response, datatable, ethResponse);
        priceP += await getOpenSeaCollectionsPrice(responseNFT, datatable, ethResponse);
        let number = getOpenSeaCollecionsAssetsNumber(response);
        number += getOpenSeaCollecionsAssetsNumber(responseNFT);
        // console.log(responseNFT);

        setNumberAssets(number);
        setPrice(priceP * ethResponse.data.ethereum.usd);
        setDatatableData(datatable);

        /* const responseNFT = await greedykiwi(deployerWallet);
        const responseNFT2 = await greedykiwi(tokensWallet);
        let priceP = 0;
        const datatable = [];
        let number = 0;

        if (responseNFT) {
          priceP += parseFloat(
            responseNFT.data.portfolio.totalPrice * ethResponse.data.ethereum.usd
          );
          number += parseFloat(responseNFT.data.portfolio.totalAssets);

          responseNFT.data.collections.map((item) => {
            datatable.push({
              asset: (
                <MDTypography
                  component="div"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {item.name}
                </MDTypography>
              ),
              balance: (
                <MDTypography
                  component="div"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {item.ownedAssetCount}
                </MDTypography>
              ),

              amountETH: (
                <MDTypography
                  component="a"
                  href="#"
                  target="_blank"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {`${formatter.format(item.totalFloorPrice)}`}
                </MDTypography>
              ),
              amountUSD: (
                <MDTypography
                  component="div"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {formatterUS.format(
                    parseFloat(item.totalFloorPrice * ethResponse.data.ethereum.usd).toFixed(2)
                  )}
                </MDTypography>
              ),
            });
            return null;
          });
        }
        if (responseNFT2) {
          priceP += parseFloat(
            responseNFT2.data.portfolio.totalPrice * ethResponse.data.ethereum.usd
          );
          number += parseFloat(responseNFT2.data.portfolio.totalAssets);

          responseNFT2.data.collections.map((item) => {
            datatable.push({
              asset: (
                <MDTypography
                  component="div"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {item.name}
                </MDTypography>
              ),
              balance: (
                <MDTypography
                  component="div"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {item.ownedAssetCount}
                </MDTypography>
              ),

              amountETH: (
                <MDTypography
                  component="a"
                  href="#"
                  target="_blank"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {`${formatter.format(item.totalFloorPrice)}`}
                </MDTypography>
              ),
              amountUSD: (
                <MDTypography
                  component="div"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {formatterUS.format(
                    parseFloat(item.totalFloorPrice * ethResponse.data.ethereum.usd).toFixed(2)
                  )}
                </MDTypography>
              ),
            });
            return null;
          });
        } 

        setNumberAssets(number);
        setPrice(priceP);
        setDatatableData(datatable); */
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setPriceLoader(0);
    };

    const refresh = async () => {
      await fetchNFTPortfolio();
    };

    refresh();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={(
                  <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" d="M18.7002 1.19C18.7002 0.51 19.2102 0 19.8902 0C20.2302 0 20.5702 0.17 20.7402 0.34L26.8602 6.46C27.0302 6.63 27.2002 6.97 27.2002 7.31C27.2002 7.99 26.6902 8.5 26.0102 8.5H22.1002C20.2302 8.5 18.7002 6.97 18.7002 5.1V1.19Z" fill="#FFFDF5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2 12.41C27.03 12.24 27.03 12.07 26.86 12.07C26.52 11.9 26.35 11.9 25.84 11.9H22.1C18.36 11.9 15.3 8.84 15.3 5.1V1.36C15.3 0.85 15.3 0.68 15.3 0.51C15.13 0.34 14.96 0.17 14.79 0.17C14.62 0 14.45 0 13.94 0H8.16C5.27 0 3.91 0 2.72 0.51C1.7 1.02 1.02 1.7 0.51 2.72C0 3.91 0 5.27 0 8.16V25.84C0 28.73 0 30.09 0.51 31.28C1.02 32.3 1.87 32.98 2.72 33.49C3.91 34 5.27 34 8.16 34H19.04C21.93 34 23.29 34 24.48 33.49C25.5 32.98 26.18 32.3 26.69 31.28C27.2 30.26 27.2 28.73 27.2 25.84V13.26C27.2 12.75 27.2 12.58 27.2 12.41ZM18.36 20.06L17 21.42C16.83 21.59 16.66 21.93 16.83 22.27L17.17 24.31C17.34 24.99 16.49 25.5 15.98 25.16L14.11 24.14C13.94 23.97 13.6 23.97 13.26 24.14L11.39 25.16C10.71 25.5 10.03 24.82 10.2 24.14L10.54 22.1C10.54 21.76 10.54 21.59 10.37 21.25L8.84 19.89C8.33 19.55 8.5 18.7 9.35 18.53L11.39 18.19C11.73 18.19 11.9 18.02 12.07 17.68L12.92 15.81C13.09 15.3 14.11 15.3 14.45 15.98L15.3 17.85C15.47 18.19 15.64 18.36 15.98 18.36L18.02 18.7C18.7 18.7 18.87 19.55 18.36 20.06Z" fill="#FFFDF5"/>
                  </svg>
                )}
                title="Collections"
                count={
                  !priceLoader ? (
                    <div>{formatter.format(datatableData.length)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={(
                  <svg width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4034 0V21.0129L0 15.6137L12.4034 0Z" fill="#FFFDF5"/>
                        <path opacity="0.6" d="M24.8068 15.6137L12.4033 21.0129V0L24.8068 15.6137Z" fill="#FFFDF5"/>
                        <path opacity="0.6" d="M24.8068 20.5752L12.4033 34.0001V25.8284L24.8068 20.5752Z" fill="#FFFDF5"/>
                        <path d="M12.4034 25.8284V34.0001L0 20.5752L12.4034 25.8284Z" fill="#FFFDF5"/>
                  </svg>
                )}
                title="NFTS"
                count={
                  !priceLoader ? (
                    <div>{formatter.format(numberAssets)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={(
                  <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M8.2 0H8.9V23.8H8.2C5.4 23.8 3.9 23.8 2.8 23.3C1.8 22.9 1.1 22.2 0.6 21.4C0 20.4 0 19.2 0 16.7V7.1C0 4.6 0 3.3 0.6 2.4C1.1 1.6 1.9 0.9 2.8 0.4C3.9 0 5.3 0 8.2 0Z" fill="#FFFDF5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9998 16.7C33.9998 19.2 33.9998 20.5 33.3998 21.4C32.8998 22.2 32.0998 22.9 31.1998 23.3C30.0998 23.8 28.6998 23.8 25.7998 23.8H11.7998V0H25.7998C28.6998 0 30.0998 0 31.1998 0.5C32.1998 0.9 32.8998 1.6 33.3998 2.5C33.9998 3.4 33.9998 4.6 33.9998 7.1C33.9998 7.3 33.7998 7.4 33.6998 7.4H25.4998C22.6998 7.4 20.3998 9.4 20.3998 11.9C20.3998 14.4 22.6998 16.4 25.4998 16.4H33.6998C33.7998 16.4 33.9998 16.5 33.9998 16.7Z" fill="#FFFDF5"/>
                    <path d="M27.1998 11.8999C27.1998 12.6999 26.3998 13.3999 25.4998 13.3999C24.5998 13.3999 23.7998 12.6999 23.7998 11.8999C23.7998 11.0999 24.5998 10.3999 25.4998 10.3999C26.3998 10.3999 27.1998 11.0999 27.1998 11.8999Z" fill="#FFFDF5"/>
                  </svg>
                )}
                title="NFT Wallet ($USD)"
                count={
                  !priceLoader ? (
                    <div>{formatterUS.format(price)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="nftTable">
              <MDBox
                mx={2}
                
                py={1}
                px={1}
                
                borderRadius="lg"
                
              >
                <MDTypography  color="white">
                  NFT Wallet Collections
                </MDTypography>
              </MDBox>
              <MDBox py={1} px={1}>
                {!priceLoader ? (
                  <DataTable
                    table={{ columns, rows: datatableData }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <CircularProgress size={26} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default NFTWallet;
