/**
=========================================================
* Clifford Inu
=========================================================
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

function DefaultInfoCard({
  // color,
  // icon,
  title,

  img,
  link,
}) {
  return (
    <Card>
      <MDBox
        // key={color}
        src={link}
        component="a"
        href={link}
        target="_blank"
        rel="noreferrer"
        // fontSize={size.lg}
        // color={socialMediaColors[color].main}
        pr={0}
        pl={0}
        lineHeight={0}
      >
        <MDBox
          component="img"
          src={img}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  // color: "info",
  img: null,
  link: null,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  link: PropTypes.string,
};

export default DefaultInfoCard;
