/**
=========================================================
* Clifford Inu
=========================================================
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ImageCard({
  // color,
  // icon,
  title,
  description,
  value,
  img,
  link,
}) {
  // Render the card img
  const renderImg = () => (
    /*    {
      // icon,
      // color,
    } */
    <MDBox
      // key={color}
      src={link}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      // fontSize={size.lg}
      // color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      <MDBox
        component="img"
        src={img}
        alt={title}
        borderRadius="lg"
        shadow="md"
        width="100%"
        height="100%"
        position="relative"
        zIndex={1}
      />
    </MDBox>
  );

  return (
    <Card>
      {img && renderImg()}
      {/*    <MDBox p={2} mx={3} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="default">{icon}</Icon>
        </MDBox>
      </MDBox> */}
      <MDBox pb={2} px={2} textAlign="left" lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="light" textTransform="capitalize">
          {title}
        </MDTypography>
        {description && (
          <MDTypography variant="h3" color="text" fontWeight="bold">
            {description}
          </MDTypography>
        )}
        {description && !value ? null : <Divider />}
        {value && (
          <MDTypography variant="h5" fontWeight="medium">
            {value}
          </MDTypography>
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
ImageCard.defaultProps = {
  // color: "info",
  value: "",
  description: "",
  img: null,
  link: null,
};

// Typechecking props for the DefaultInfoCard
ImageCard.propTypes = {
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  img: PropTypes.string,
  link: PropTypes.string,
};

export default ImageCard;
