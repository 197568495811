/**
=========================================================
* Meta Capital
=========================================================
*/
import React, { useState, useEffect } from "react";
import { utils } from "ethers";
import './styles.scss'
import { Contract } from "@ethersproject/contracts";
import {
  // useSendTransaction,
  useContractCall,
  useEthers,
  useContractFunction,
  useTokenBalance,
} from "@usedapp/core";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";
import Divider from "@mui/material/Divider";
import icon2 from "../../assets/images/icon2.png"
import icon3 from "../../assets/images/icon3.png"
import MDTypography from "components/MDTypography";
import { CircularProgress, TextField } from "@material-ui/core";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setOpenConfigurator } from "context";

import stakingABI from "../../abi/stakingCLIFF.json";
import approvalABI from "../../abi/approvalCLIFF.json";
// import redABI from "../../abi/redABI.json";

// utils
import {
  // getCLIFFItem,
  // portfolio,
  coingeckoCLIFF,
  // transfers, // balance
  // getCLIFFBalanceTransfers,
} from "./utils";

axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

// Contracts
const wethApprovalContractAddress = "0x1B9BAF2A3EdeA91eE431f02d449a1044d5726669";
const contractSCLIFF = "0xfd0d8e8f509e6869400ccb9730a06c25172ec119";
const contractRED = "0x31339d225bd6fb6b1f6ab440780f35f6d32863ba";

const wethApprovalInterface = new utils.Interface(approvalABI);
const wethStakingInterface = new utils.Interface(stakingABI);
// const wethREDInterface = new utils.Interface(redABI);

export function useAllowance(account) {
  const owner = account;
  const spender = contractSCLIFF;
  const [allowance] =
    useContractCall(
      owner && {
        abi: wethApprovalInterface,
        address: wethApprovalContractAddress,
        method: "allowance",
        args: [owner, spender],
      }
    ) ?? [];
  return allowance;
}

export function useUserInfo(account) {
  const address = account;
  const [amount, rewardDebt, lastDepositedAt] =
    useContractCall(
      address && {
        abi: wethStakingInterface,
        address: contractSCLIFF,
        method: "userInfo",
        args: [address],
      }
    ) ?? [];
  return [amount, rewardDebt, lastDepositedAt];
}
export function usePendingReward(account) {
  const user = account;
  const [pendingReward] =
    useContractCall(
      user && {
        abi: wethStakingInterface,
        address: contractSCLIFF,
        method: "pendingReward",
        args: [user],
      }
    ) ?? [];
  return [pendingReward];
}

export function useStakedSupply() {
  const [stakedSupply] =
    useContractCall({
      abi: wethStakingInterface,
      address: contractSCLIFF,
      method: "stakedSupply",
      args: [],
    }) ?? [];
  return [stakedSupply];
}

export function useRewardPerBlock() {
  const [rewardPerBlock] =
    useContractCall({
      abi: wethStakingInterface,
      address: contractSCLIFF,
      method: "rewardPerBlock",
      args: [],
    }) ?? [];
  return [rewardPerBlock];
}

function Portfolio() {
  const { account } = useEthers();
  // const tokenBalance = useTokenBalance(contractSCLIFF, account);
  const [sCliffInfo] = useUserInfo(account);

  const [rewardDebt] = usePendingReward(account);
  const [totalSupply] = useStakedSupply();
  const totalSupplyF = totalSupply ? formatUnits(totalSupply, 18) : 0;
  const [rewardPerBlock] = useRewardPerBlock();
  const rewardPerBlockF = rewardPerBlock ? formatUnits(rewardPerBlock, 9) : 0;

  const cliffTokenBalance = useTokenBalance(wethApprovalContractAddress, account);
  const allowance = useAllowance(account);
  const allowanceF = allowance ? formatUnits(allowance, 18) : 0;

  const redTokenBalance = useTokenBalance(contractRED, account);

  //  console.log("redTokenBalance ", redTokenBalance);
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator } = controller;
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const [disabled, setDisabled] = useState(false);
  const [disabledApproved, setDisabledApproved] = useState(allowanceF && allowanceF > 0);

  const [cliffPrice, setCLIFFPrice] = useState(0);
  const [cliffPriceUSD, setCLIFFPriceUSD] = useState(0);
  const [cliffBalanceLoader, setCLIFFBalanceLoader] = useState(0);

  const [stakingCLIFFAmount, setStakingCLIFFAmount] = useState(0);
  const [unStakingCLIFFAmount, setUnStakingCLIFFAmount] = useState(0);

  const [approvalError, setApprovalError] = useState(null);

  const contractStakingETH = new Contract(contractSCLIFF, wethStakingInterface);
  const contractApprovalETH = new Contract(wethApprovalContractAddress, wethApprovalInterface);

  // transactionName: "Wrap"
  const { state, send: useDeposit } = useContractFunction(contractStakingETH, "deposit", {});
  const { state: stateWithdraw, send: useWithdraw } = useContractFunction(
    contractStakingETH,
    "withdraw",
    {}
  );

  const { state: stateApprove, send: useApprove } = useContractFunction(
    contractApprovalETH,
    "approve",
    {}
  );

  // 1. APPROVE
  useEffect(() => {
    // console.log("stateApprove ", stateApprove);

    if (stateApprove.status === "Success") {
      setDisabled(false);
      setDisabledApproved(true);
    }
    if (stateApprove.status === "Exception" || state.status === "Fail") {
      setDisabled(false);
      setApprovalError(stateApprove.errorMessage);
    }
  }, [stateApprove]);

  // 2. DEPOSIT
  useEffect(() => {
    // console.log(" state ", state);
    if (
      state.status !== "Mining" &&
      state.status !== "Exception" &&
      state.status !== "PendingSignature" &&
      state.status !== "Fail"
    ) {
      setDisabled(false);
      setStakingCLIFFAmount(0);
      /* const scliff = SCLIFFBalance();
      setSCLIFFBalance(scliff); */
    }
    if (state.status === "Exception" || state.status === "Fail") {
      setDisabled(false);
      setApprovalError(state.errorMessage);
    }
  }, [state]);

  // 3. WITHDRAWAL
  useEffect(() => {
    // console.log(" stateWithdraw ", stateWithdraw);
    if (
      stateWithdraw.status !== "Mining" &&
      stateWithdraw.status !== "Exception" &&
      stateWithdraw.status !== "PendingSignature" &&
      stateWithdraw.status !== "Fail"
    ) {
      setDisabled(false);
      setUnStakingCLIFFAmount(0);
      /* const scliff = SCLIFFBalance();
      setSCLIFFBalance(scliff); */
    }
    if (stateWithdraw.status === "Exception" || stateWithdraw.status === "Fail") {
      setDisabled(false);
      setApprovalError(stateWithdraw.errorMessage);
    }
  }, [stateWithdraw]);

  const approveCLIFF = () => {
    try {
      setDisabled(true);
      setApprovalError(null);
      const maxAllowance = BigNumber.from("1000000000000000000").mul("1000000000000000000");

      useApprove(contractSCLIFF, maxAllowance);
    } catch (e) {
      console.log(e);
    }
  };

  const depositCLIFF = (depo) => {
    try {
      setDisabled(true);
      setApprovalError(null);

      const stake = depo
        ? BigNumber.from(0)
        : BigNumber.from(stakingCLIFFAmount).mul("1000000000000000000");
      useDeposit(stake);
    } catch (e) {
      setDisabled(false);
      setApprovalError(e.message);

      console.log(e);
    }
  };
  const withdrawCLIFF = () => {
    try {
      setDisabled(true);
      setApprovalError(null);
      const unstake = BigNumber.from(unStakingCLIFFAmount).mul("1000000000000000000");

      useWithdraw(unstake);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setDisabledApproved(allowanceF && allowanceF > 0);
    // console.log("Allowance ", allowanceF);
  }, [allowance]);

  useEffect(() => {
    const fetchCLIFFPrice = async () => {
      setCLIFFBalanceLoader(1);

      try {
        const cliffResponse = await coingeckoCLIFF();
        const price = cliffResponse.data["clifford-inu"].eth;
        const priceUSD = cliffResponse.data["clifford-inu"].usd;
        setCLIFFPrice(price);
        setCLIFFPriceUSD(priceUSD);
      } catch (error) {
        console.error(error.message);
      }
      setCLIFFBalanceLoader(0);
    };

    const refresh = async () => {
      await Promise.all([
        fetchCLIFFPrice(),
        // fetchApproved(account),
        // fetchPrice(CLIFF_Contract),
      ]);
    };

    if (account) {
      refresh();
      if (openConfigurator) {
        handleConfiguratorOpen();
      }
    } else if (!openConfigurator) {
      handleConfiguratorOpen();
    }
  }, [account]);

  const disableMessage = disabledApproved ? "Already approved" : "Approve $CLIFF";

  // console.log("yo ", sBalance);
  return account ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon={(
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xmlSpace="preserve">  <image id="image0" width="40" height="40" x="0" y="0"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
                      CXBIWXMAAAsTAAALEwEAmpwYAAAMsElEQVRYw+2YaYyd1XnHf89517vM3NlsjB0z8RjjBQOGgsEs
                      oVRAUZYiglBVlW4qUtt8aJugRApNWUrVtFBVrfKBSm1JE6lIDWqbqhSVJEoaQ1mMDY7teLyOPfbY
                      49nuzNxl7n2Xc55+uOOxjQmQQvupRzq6m+5zfu//vM//ed4D/z8+3JAPG2D6K7+3Mdi3a23NDxa8
                      VUPrvOMHt7Znq2+YUvlId1e51JiYPLzqh/sO/J8Cnv7879zeFfubG6WuW2T06H3F4wfiLIwJoxga
                      NRTIBALjUW+3Wyrmn8tR+Np8M9kzuH3vy/9rgPUH71qerr36d02p9w+KLic5+CPqJ45iURVEUKdq
                      DKKd2M6p+iJS9gyBERppTpa5J6UcfG3lf+ye+kgBzzz2xWcDP7i30NXbNzczRbbvdTVTp1XFJ2/l
                      pO2MPLOoKsYY/NAjKAT4cQiewamKh0jFM7RhppXabw9u3/fQhwbc+elLi4M3/MozPZWBX51NUtrH
                      DygHf0Q6Ncf8bEZ1rk0jycmcA1EEMAK+QOQLpUJApa9Eub+MV4hQVSJBKp7HzEL69SOnFj53x+ho
                      +38EOP6Hn7+jFJef8Qql9dXavLJ/p2b79zMz3uL0XE5LHXEkxKEQ+oLnCSKdkFYht448s5BZioGh
                      f3k35RUVJPBRp9Lre5Irw/OZ/vbQy3u3/1SA+x7YFC7bfO+B7krfmqmpCeWt7dR2H+L0rDCVWgpF
                      KMRC3QhqDAbwRDB01PPpzADwUZLUkiUZPYWQ/sF+gnIBZ5VlgSczKUdGXu7deAc/zD8Q4PjDD5b8
                      7lXfLHb1frZanXb6xveZfn2Y+sAQSQRy+jgUQp7KHEcdmMVAZnF6i5ABEIsQAJ8JhJs8w0I7o4Cy
                      fHCAoKeEyx29vm9qmX1+slX99et3jS+cz2LeDdCWV36jt9L/2Wq9ru7tV2T69WFprNoovduuFH/m
                      lHQXIwl9T8YUaYNkICmd902QGsiMIuOKHMpy2WFVTlqVsicSFkNp+55MnpyRrN4S8Y3M5rkuj/0H
                      egsDz76T5SLAyb945NIwjD891UqUQ7u1vmOfNvoGdeU9N2tjxxtaUKfzntG/T6y2rdUQ1AP1z3v1
                      QQNQY616zmrJ5bony3V35jRGNAp9TT2js2NVJc1UjNHpJNVAzL27t923/D0B8/n06VKxHGUTJ0je
                      ektmkqIsv/dOmX/zDUmnpyQuRDKvyAtJJgvWSpbl0kpzaaa5WOdEOlkiubWSOSuAeM7J9jSTHySZ
                      +CCiiPE9aVqV2sS8iEFShG5f4y7v6FM/EXD80S880NfV+8uzzbrj0F6tjs5r1x2f0HzsmNZ+vE+D
                      UkEVVFD1ba65qvq+ak9RtK8kCk7T3GpurWYuVwFlcRoVDaHznariVMU32qi1NW+01fONzmWZWxbo
                      rx255Zr7zjL55wNqkl5LEKKjR6V9aIRs5RqiAkx8dzt+HHeiS8dCWgqt3HLP+m4G+yKMGN4cbbDr
                      ZAPfCIKg52WiAgGCLH7u/K6kQKPaoLergD37vQm3AP9ykYIaF36uneWOsRFdaIpmpaJOv7xdPWPU
                      +EYRWVJFnWop9LWnXFAQbSWZblkV642DXZpaVQXtSIVa51RVNZBF9c4qCYox2lpI1CWZGs9o4qxT
                      cXdepOCZP3nsFwpBeGN9ekLd9BS11Gf68EF6i2CiEF3SA3LniOOQL/3SbawoCq12QpblzM7MsDad
                      4pKuXkSE3FqctZRKRV44Wsdr50t77gCnHTlTq6QLCVFvmXqS0+MnNx/5xJZPXr5994tLgNnU+FDl
                      sstVqxPqGm0aCwmh5wiCEBEQMUtbI85RiAKu33ojseTUqpPkrTrLlvVTKpWYGh8nCgyVSoVKb4X+
                      vh52zOwiPFVd8syzk0XgLLUUohib1IjEF5vI0AUKGk+a6iw6OSZZBpm1RAWDipzn5oIqlDG0mm2+
                      8lfPUY4jnCq1Wp27t3yMGzd9nGpL+ddXhxnos/jBPKqjnJycZ9opidNOsaYjnwioCFlqIYwAcBh1
                      fql5IWDfihvc8UMq1UmseoDDN4KRzg1/tlIkAse6DQUXsHb1JYTiCP2Yel+FcrFA2k7o7eth3cYr
                      iX2fRquJVQGZZXhZFzO5o1fPNRUAIuCyHDEeRgxgiczCDcDXz23x269e366eEfUDnEs7VyiGs3e0
                      AJ4qdQ+Gl/lUNOQ377+bQs8Ktu95lcsv24BfPc3EyB6Ghtaz6dYhDp44zNYNW6BdY/TJv+b69QPU
                      956ku5WixixqeDbrFDVeR01nsWnrhguyWJq1/4riQsezDOrJkquc52eqMWgJUV9Em622zmeBvrR3
                      vx6ZaSh+QT2cmqCow9VMv/XGDp23ZW22Em22Ev3bf39b2422+sYs+ul504iKZxRVDcXT3AavXADo
                      XXPjiHT1iForXmDEiIgqonSmgCwo8lo7l+mWlcGegvieEZk9LI996lOypZjK7NiwEJZk7vQhuUIn
                      5av33S/B/GFRlwsgxSCQqkMSp+JUl2KrqnhhIBgj6qwgRkxUHrkAUM+MrZTGPBijQeRp5HvqnHau
                      TtFQRKdV9am5ln7rwJwOFFVr9ZoqohY0Dox2F0Lt761odzHSUBON4lBFRNMsU5tbzUX0z1qpHsis
                      houeKqDinIblUmennFODw3PNlRckSXLs4M62ZwQxmBgKhZD5pNnxVYFEYbXn8ceVAo/Mt/jm6yf4
                      /pF/4JlHf4vRUxO8uH0XvjmX77kd47KVp7jlug18+S+fY2x6jt8oRlzpGZb5hrONnwLGOcK+HlyS
                      IAgLSSatanPnBYBD//njfzx+17V/VFR3eRtHd6VA7VSj84yxaDSKcl0U8Fg3fGmmTjPN6C4X2Hd4
                      lOe+89o7+w6uW3cZd267mtNTVVrWscYz3BoH1FSXEgRVotAnGugjnxyn6HtUW3LgqoPH/umiWuz3
                      DeyN56bXLVhLsb9APBGQZ47AdBCtdALeFAd8safIn7dTvvD0N5irNVnVU+b+2zbgnBL6HvtPTLPz
                      yBR/+uy3MQoPliIu8w11Vdx52UuWU1q9Agk8XLNBFEUEcbTnXZuFbLa606jej+L8kkf/QBdnJqsQ
                      eoh0/MYKGFV+vhgx45Rn9hwC4Pc/cz0P3L6RJM0JPMPNG1cxPPYDvrdjH2EccXepwJBvaOn5N74S
                      idJ1xRrs9DSCYJyarFnfea40nN8sgDl2+6aXK0GwrZ7nkML48DgLxhLGQSeXF30xBFJV/qbR5vlW
                      xl1bBukrRlinGIEoDti19ySlmTrlwOfBQsiawJDruYWlnbBs/RrKV6yhfXA/3UHI1MzCK5v2H//Z
                      xXJ9oYICbjQKH/fhO6I4iQ3LVvcyfmwS51u8wOuUJ4UMJRT4XDmirfDCmyO0nVuqtTmwOg74aneB
                      Xs+jJZDreTU4zaj0ddO96QqSkcN4YhAVkyTRY2fhLrCZJcju+JXpVnq84nsmt06C/oIsX9UrppWL
                      zdxZ1xFAUkUMIg+VQumKfIkKoUSFUPw4lEohlBtCX4yIZIs+utQjpLmUipH03bJV0jOnxC4sSCUI
                      zEw7OzIua159h2gXj7evGfz4QKX4vcj31zatc54Rkok60+Nz5IHBBB4slhcDOFW+XGuxJ3P0GKGl
                      ys2Bx8OlqFN/AFlsOiRJ6OrtYuDWrdi5Ktn4aUpRZGpzrcNnxubuvG129sT7AgIM33HVzyz3ZGdq
                      HS2r6nkGV2sxNz7HQpqjvod4sgTZcI5J6ygt3qcRUJbFqwDEWgIcPUOr6d68gWxinHxqkmIciSaW
                      kZH6tbdOnd590Y7yHmN42/onejzziBd4fiN3zvM9jHW0q00ac02SNMeJgBE8AaNgnaIozoF1DuMc
                      gW8oLe+j+4o1+F0l0pOj2OYC5TA0WZpncwv5k5v3jTz5bgzvfzZz1eBNK3qKz5fC4GPzWY4i6vkG
                      rJI3E5JmQpZk5LlFrcNpRzLjeQTFmLi/h3h5P14xxs7Pks/MIGKkJ/SZmW2fOD42+8Cd1eqOn7T+
                      Bzrd2r9tw+YujxdiMYPOExq5U2NEjedhzOI2Orf4BAISBEixiPg+6hx2oYlrNHGqUg4CIXe0Mnt8
                      fKz+yW1nzgy/19of+PjtaSjdc+Vl93RVCo8vL0Sb29bRco7EdbpP6TwXnPuDc2huASXyPCl4HhHC
                      qerCntnZ1uMvjo2/9AQsvN+6P/UJ60tXX1JaF1eeyDO7UQLZuqwYDwAdpYBElUgEDxAjOKucmW9N
                      GeFNY2T/v7098ujD0Pqg632oM+rvruzqX7v60odsmg/V2llWKoXr4sC/slZP96VZfri/OwrTtjuy
                      e+/03/0iteqHWesjGzu5tPhRxvtvrL2iClFhHX8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQt
                      MjlUMDc6MTM6MjYrMDM6MDCmacO7AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTI5VDA3OjEz
                      OjI2KzAzOjAw1zR7BwAAAABJRU5ErkJggg==" />
                    </svg>
                    )}
                    title="Total Value Locked ($CLIFF)"
                    icon2={(
                      <svg width="103" height="52" viewBox="0 0 103 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_235_519" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="103" height="52">
                          <rect x="0.700195" width="102.282" height="51.471" fill="#C4C4C4"/>
                          </mask>
                          <g mask="url(#mask0_235_519)">
                          <path d="M6.81993 43.2242C6.81993 43.2242 11.3898 38.8657 20.1589 28.6493C28.9279 18.4329 43.8807 14.8713 55.6023 22.1027C67.3238 29.3342 80.1006 46.8753 99.3945 14.9261C101.222 11.9644 103.018 9.27155 104.69 6.8366" stroke="url(#paint0_linear_235_519)" stroke-width="2" stroke-miterlimit="10"/>
                          </g>
                          <defs>
                          <linearGradient id="paint0_linear_235_519" x1="102.652" y1="25.0753" x2="8.94875" y2="33.6538" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#16C784"/>
                          <stop offset="1" stop-color="#16C784" stop-opacity="0"/>
                          </linearGradient>
                          </defs>
                      </svg>
                    )}
                    count={formatter.format(parseFloat(totalSupplyF).toFixed(2))}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon={(
                      <img src={icon2} width={40} height={40}/>
                    )}
                    color="primary"
                    title="Total Value Locked ($USD)"
                    count={formatterUS.format(parseFloat(totalSupplyF * cliffPriceUSD).toFixed(2))}
                    icon2={(
                      <svg width="103" height="52" viewBox="0 0 103 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_235_522" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="103" height="52">
                        <rect width="102.282" height="51.471" transform="matrix(1 0 0 -1 0.700195 51.4712)" fill="#C4C4C4"/>
                        </mask>
                        <g mask="url(#mask0_235_522)">
                        <path d="M6.81993 8.24646C6.81993 8.24646 11.3898 12.605 20.1589 22.8214C28.9279 33.0378 43.8807 36.5994 55.6023 29.368C67.3238 22.1365 80.1006 4.59538 99.3945 36.5446C101.222 39.5063 103.018 42.1992 104.69 44.6341" stroke="url(#paint0_linear_235_522)" stroke-width="2" stroke-miterlimit="10"/>
                        </g>
                        <defs>
                        <linearGradient id="paint0_linear_235_522" x1="102.652" y1="26.3954" x2="8.94875" y2="17.8169" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF4D5E"/>
                        <stop offset="1" stop-color="#FF4D5E" stop-opacity="0"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    )}
                    /*   percentage={
                  !cliffBalanceLoader
                    ? {
                        color: "warning",
                        amount: `${formatter.format(
                          parseFloat((deadSupply / 1000000000) * 100).toFixed(2)
                        )}%`,
                        label: "($CLIFF) Burned",
                      }
                    : {}
                } */
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon={(
                      <img src={icon3} width={40} height={40}/>
                    )}
                    icon2={(
                      <svg width="119" height="59" viewBox="0 0 119 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_235_528" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="119" height="59">
                        <rect width="119" height="59" fill="#C4C4C4"/>
                        </mask>
                        <g mask="url(#mask0_235_528)">
                        <path d="M121 14C121 14 110.318 20.7632 103.077 24.8009C95.8368 28.8387 85.6291 30.1509 77.5579 26.1132C69.4866 22.0755 59.0415 20.3594 49.0712 26.2142C39.1009 32.0689 23.4332 46.4028 1 44.8887" stroke="url(#paint0_linear_235_528)" stroke-width="2" stroke-miterlimit="10"/>
                        </g>
                        <defs>
                        <linearGradient id="paint0_linear_235_528" x1="120.881" y1="14" x2="-19.4877" y2="16.2611" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#16C784"/>
                        <stop offset="1" stop-color="#12121A" stop-opacity="0"/>
                        <stop offset="1" stop-color="#16C784"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    )}
                    color="success"
                    title="Balance $CLIFF (in $ETH)"
                    count={
                      !cliffBalanceLoader ? (
                        <div>
                          {cliffTokenBalance
                            ? formatter.format(cliffPrice * formatUnits(cliffTokenBalance, 18))
                            : 0}
                        </div>
                      ) : (
                        <CircularProgress color="primary" size={26} />
                      )
                    }
                    /*   percentage={
                  !cliffBalanceLoader
                    ? {
                        color: "warning",
                        amount: `${formatter.format(
                          parseFloat((deadSupply / 1000000000) * 100).toFixed(2)
                        )}%`,
                        label: "($CLIFF) Burned",
                      }
                    : {}
                } */
                  />
                </MDBox>
              </Grid>
              
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon={(
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xmlSpace="preserve">  <image id="image0" width="40" height="40" x="0" y="0"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                      AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
                      CXBIWXMAAAsTAAALEwEAmpwYAAAMsElEQVRYw+2YaYyd1XnHf89517vM3NlsjB0z8RjjBQOGgsEs
                      oVRAUZYiglBVlW4qUtt8aJugRApNWUrVtFBVrfKBSm1JE6lIDWqbqhSVJEoaQ1mMDY7teLyOPfbY
                      49nuzNxl7n2Xc55+uOOxjQmQQvupRzq6m+5zfu//vM//ed4D/z8+3JAPG2D6K7+3Mdi3a23NDxa8
                      VUPrvOMHt7Znq2+YUvlId1e51JiYPLzqh/sO/J8Cnv7879zeFfubG6WuW2T06H3F4wfiLIwJoxga
                      NRTIBALjUW+3Wyrmn8tR+Np8M9kzuH3vy/9rgPUH71qerr36d02p9w+KLic5+CPqJ45iURVEUKdq
                      DKKd2M6p+iJS9gyBERppTpa5J6UcfG3lf+ye+kgBzzz2xWcDP7i30NXbNzczRbbvdTVTp1XFJ2/l
                      pO2MPLOoKsYY/NAjKAT4cQiewamKh0jFM7RhppXabw9u3/fQhwbc+elLi4M3/MozPZWBX51NUtrH
                      DygHf0Q6Ncf8bEZ1rk0jycmcA1EEMAK+QOQLpUJApa9Eub+MV4hQVSJBKp7HzEL69SOnFj53x+ho
                      +38EOP6Hn7+jFJef8Qql9dXavLJ/p2b79zMz3uL0XE5LHXEkxKEQ+oLnCSKdkFYht448s5BZioGh
                      f3k35RUVJPBRp9Lre5Irw/OZ/vbQy3u3/1SA+x7YFC7bfO+B7krfmqmpCeWt7dR2H+L0rDCVWgpF
                      KMRC3QhqDAbwRDB01PPpzADwUZLUkiUZPYWQ/sF+gnIBZ5VlgSczKUdGXu7deAc/zD8Q4PjDD5b8
                      7lXfLHb1frZanXb6xveZfn2Y+sAQSQRy+jgUQp7KHEcdmMVAZnF6i5ABEIsQAJ8JhJs8w0I7o4Cy
                      fHCAoKeEyx29vm9qmX1+slX99et3jS+cz2LeDdCWV36jt9L/2Wq9ru7tV2T69WFprNoovduuFH/m
                      lHQXIwl9T8YUaYNkICmd902QGsiMIuOKHMpy2WFVTlqVsicSFkNp+55MnpyRrN4S8Y3M5rkuj/0H
                      egsDz76T5SLAyb945NIwjD891UqUQ7u1vmOfNvoGdeU9N2tjxxtaUKfzntG/T6y2rdUQ1AP1z3v1
                      QQNQY616zmrJ5bony3V35jRGNAp9TT2js2NVJc1UjNHpJNVAzL27t923/D0B8/n06VKxHGUTJ0je
                      ektmkqIsv/dOmX/zDUmnpyQuRDKvyAtJJgvWSpbl0kpzaaa5WOdEOlkiubWSOSuAeM7J9jSTHySZ
                      +CCiiPE9aVqV2sS8iEFShG5f4y7v6FM/EXD80S880NfV+8uzzbrj0F6tjs5r1x2f0HzsmNZ+vE+D
                      UkEVVFD1ba65qvq+ak9RtK8kCk7T3GpurWYuVwFlcRoVDaHznariVMU32qi1NW+01fONzmWZWxbo
                      rx255Zr7zjL55wNqkl5LEKKjR6V9aIRs5RqiAkx8dzt+HHeiS8dCWgqt3HLP+m4G+yKMGN4cbbDr
                      ZAPfCIKg52WiAgGCLH7u/K6kQKPaoLergD37vQm3AP9ykYIaF36uneWOsRFdaIpmpaJOv7xdPWPU
                      +EYRWVJFnWop9LWnXFAQbSWZblkV642DXZpaVQXtSIVa51RVNZBF9c4qCYox2lpI1CWZGs9o4qxT
                      cXdepOCZP3nsFwpBeGN9ekLd9BS11Gf68EF6i2CiEF3SA3LniOOQL/3SbawoCq12QpblzM7MsDad
                      4pKuXkSE3FqctZRKRV44Wsdr50t77gCnHTlTq6QLCVFvmXqS0+MnNx/5xJZPXr5994tLgNnU+FDl
                      sstVqxPqGm0aCwmh5wiCEBEQMUtbI85RiAKu33ojseTUqpPkrTrLlvVTKpWYGh8nCgyVSoVKb4X+
                      vh52zOwiPFVd8syzk0XgLLUUohib1IjEF5vI0AUKGk+a6iw6OSZZBpm1RAWDipzn5oIqlDG0mm2+
                      8lfPUY4jnCq1Wp27t3yMGzd9nGpL+ddXhxnos/jBPKqjnJycZ9opidNOsaYjnwioCFlqIYwAcBh1
                      fql5IWDfihvc8UMq1UmseoDDN4KRzg1/tlIkAse6DQUXsHb1JYTiCP2Yel+FcrFA2k7o7eth3cYr
                      iX2fRquJVQGZZXhZFzO5o1fPNRUAIuCyHDEeRgxgiczCDcDXz23x269e366eEfUDnEs7VyiGs3e0
                      AJ4qdQ+Gl/lUNOQ377+bQs8Ktu95lcsv24BfPc3EyB6Ghtaz6dYhDp44zNYNW6BdY/TJv+b69QPU
                      956ku5WixixqeDbrFDVeR01nsWnrhguyWJq1/4riQsezDOrJkquc52eqMWgJUV9Em622zmeBvrR3
                      vx6ZaSh+QT2cmqCow9VMv/XGDp23ZW22Em22Ev3bf39b2422+sYs+ul504iKZxRVDcXT3AavXADo
                      XXPjiHT1iForXmDEiIgqonSmgCwo8lo7l+mWlcGegvieEZk9LI996lOypZjK7NiwEJZk7vQhuUIn
                      5av33S/B/GFRlwsgxSCQqkMSp+JUl2KrqnhhIBgj6qwgRkxUHrkAUM+MrZTGPBijQeRp5HvqnHau
                      TtFQRKdV9am5ln7rwJwOFFVr9ZoqohY0Dox2F0Lt761odzHSUBON4lBFRNMsU5tbzUX0z1qpHsis
                      houeKqDinIblUmennFODw3PNlRckSXLs4M62ZwQxmBgKhZD5pNnxVYFEYbXn8ceVAo/Mt/jm6yf4
                      /pF/4JlHf4vRUxO8uH0XvjmX77kd47KVp7jlug18+S+fY2x6jt8oRlzpGZb5hrONnwLGOcK+HlyS
                      IAgLSSatanPnBYBD//njfzx+17V/VFR3eRtHd6VA7VSj84yxaDSKcl0U8Fg3fGmmTjPN6C4X2Hd4
                      lOe+89o7+w6uW3cZd267mtNTVVrWscYz3BoH1FSXEgRVotAnGugjnxyn6HtUW3LgqoPH/umiWuz3
                      DeyN56bXLVhLsb9APBGQZ47AdBCtdALeFAd8safIn7dTvvD0N5irNVnVU+b+2zbgnBL6HvtPTLPz
                      yBR/+uy3MQoPliIu8w11Vdx52UuWU1q9Agk8XLNBFEUEcbTnXZuFbLa606jej+L8kkf/QBdnJqsQ
                      eoh0/MYKGFV+vhgx45Rn9hwC4Pc/cz0P3L6RJM0JPMPNG1cxPPYDvrdjH2EccXepwJBvaOn5N74S
                      idJ1xRrs9DSCYJyarFnfea40nN8sgDl2+6aXK0GwrZ7nkML48DgLxhLGQSeXF30xBFJV/qbR5vlW
                      xl1bBukrRlinGIEoDti19ySlmTrlwOfBQsiawJDruYWlnbBs/RrKV6yhfXA/3UHI1MzCK5v2H//Z
                      xXJ9oYICbjQKH/fhO6I4iQ3LVvcyfmwS51u8wOuUJ4UMJRT4XDmirfDCmyO0nVuqtTmwOg74aneB
                      Xs+jJZDreTU4zaj0ddO96QqSkcN4YhAVkyTRY2fhLrCZJcju+JXpVnq84nsmt06C/oIsX9UrppWL
                      zdxZ1xFAUkUMIg+VQumKfIkKoUSFUPw4lEohlBtCX4yIZIs+utQjpLmUipH03bJV0jOnxC4sSCUI
                      zEw7OzIua159h2gXj7evGfz4QKX4vcj31zatc54Rkok60+Nz5IHBBB4slhcDOFW+XGuxJ3P0GKGl
                      ys2Bx8OlqFN/AFlsOiRJ6OrtYuDWrdi5Ktn4aUpRZGpzrcNnxubuvG129sT7AgIM33HVzyz3ZGdq
                      HS2r6nkGV2sxNz7HQpqjvod4sgTZcI5J6ygt3qcRUJbFqwDEWgIcPUOr6d68gWxinHxqkmIciSaW
                      kZH6tbdOnd590Y7yHmN42/onejzziBd4fiN3zvM9jHW0q00ac02SNMeJgBE8AaNgnaIozoF1DuMc
                      gW8oLe+j+4o1+F0l0pOj2OYC5TA0WZpncwv5k5v3jTz5bgzvfzZz1eBNK3qKz5fC4GPzWY4i6vkG
                      rJI3E5JmQpZk5LlFrcNpRzLjeQTFmLi/h3h5P14xxs7Pks/MIGKkJ/SZmW2fOD42+8Cd1eqOn7T+
                      Bzrd2r9tw+YujxdiMYPOExq5U2NEjedhzOI2Orf4BAISBEixiPg+6hx2oYlrNHGqUg4CIXe0Mnt8
                      fKz+yW1nzgy/19of+PjtaSjdc+Vl93RVCo8vL0Sb29bRco7EdbpP6TwXnPuDc2huASXyPCl4HhHC
                      qerCntnZ1uMvjo2/9AQsvN+6P/UJ60tXX1JaF1eeyDO7UQLZuqwYDwAdpYBElUgEDxAjOKucmW9N
                      GeFNY2T/v7098ujD0Pqg632oM+rvruzqX7v60odsmg/V2llWKoXr4sC/slZP96VZfri/OwrTtjuy
                      e+/03/0iteqHWesjGzu5tPhRxvtvrL2iClFhHX8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQt
                      MjlUMDc6MTM6MjYrMDM6MDCmacO7AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTI5VDA3OjEz
                      OjI2KzAzOjAw1zR7BwAAAABJRU5ErkJggg==" />
                    </svg>

                    )}
                    title="Balance ($RED)"
                    count={
                      <div>
                        {redTokenBalance
                          ? formatter.format(parseFloat(formatUnits(redTokenBalance, 9)).toFixed(2))
                          : 0}
                      </div>
                    }
                    icon2={(
                      <svg width="128" height="61" viewBox="0 0 128 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_235_531" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="61">
                          <rect width="128" height="61" fill="#C4C4C4"/>
                          </mask>
                          <g mask="url(#mask0_235_531)">
                          <path d="M18 32.9412C18 32.9412 29.4133 43.5364 49.2188 42.9787C69.0242 42.4211 78.1996 27.2533 84.4657 20.3386C90.7319 13.4238 100.802 7.51285 112.44 13.4238C117.139 15.7659 123.293 23.5729 129 31.9375" stroke="url(#paint0_linear_235_531)" stroke-width="2" stroke-miterlimit="10"/>
                          </g>
                          <defs>
                          <linearGradient id="paint0_linear_235_531" x1="129.56" y1="36.1755" x2="17.666" y2="31.1237" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FF4D5E"/>
                          <stop offset="1" stop-color="#FF4D5E" stop-opacity="0"/>
                          </linearGradient>
                          </defs>
                      </svg>
                    )}
                  />
                </MDBox>
              </Grid>
              
            </Grid>
            
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Grid item xs={12} md={12} lg={12} className="rewardCard">
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon={(
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xmlSpace="preserve">  <image id="image0" width="40" height="40" x="0" y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                        AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
                        CXBIWXMAAAsTAAALEwEAmpwYAAAMsElEQVRYw+2YaYyd1XnHf89517vM3NlsjB0z8RjjBQOGgsEs
                        oVRAUZYiglBVlW4qUtt8aJugRApNWUrVtFBVrfKBSm1JE6lIDWqbqhSVJEoaQ1mMDY7teLyOPfbY
                        49nuzNxl7n2Xc55+uOOxjQmQQvupRzq6m+5zfu//vM//ed4D/z8+3JAPG2D6K7+3Mdi3a23NDxa8
                        VUPrvOMHt7Znq2+YUvlId1e51JiYPLzqh/sO/J8Cnv7879zeFfubG6WuW2T06H3F4wfiLIwJoxga
                        NRTIBALjUW+3Wyrmn8tR+Np8M9kzuH3vy/9rgPUH71qerr36d02p9w+KLic5+CPqJ45iURVEUKdq
                        DKKd2M6p+iJS9gyBERppTpa5J6UcfG3lf+ye+kgBzzz2xWcDP7i30NXbNzczRbbvdTVTp1XFJ2/l
                        pO2MPLOoKsYY/NAjKAT4cQiewamKh0jFM7RhppXabw9u3/fQhwbc+elLi4M3/MozPZWBX51NUtrH
                        DygHf0Q6Ncf8bEZ1rk0jycmcA1EEMAK+QOQLpUJApa9Eub+MV4hQVSJBKp7HzEL69SOnFj53x+ho
                        +38EOP6Hn7+jFJef8Qql9dXavLJ/p2b79zMz3uL0XE5LHXEkxKEQ+oLnCSKdkFYht448s5BZioGh
                        f3k35RUVJPBRp9Lre5Irw/OZ/vbQy3u3/1SA+x7YFC7bfO+B7krfmqmpCeWt7dR2H+L0rDCVWgpF
                        KMRC3QhqDAbwRDB01PPpzADwUZLUkiUZPYWQ/sF+gnIBZ5VlgSczKUdGXu7deAc/zD8Q4PjDD5b8
                        7lXfLHb1frZanXb6xveZfn2Y+sAQSQRy+jgUQp7KHEcdmMVAZnF6i5ABEIsQAJ8JhJs8w0I7o4Cy
                        fHCAoKeEyx29vm9qmX1+slX99et3jS+cz2LeDdCWV36jt9L/2Wq9ru7tV2T69WFprNoovduuFH/m
                        lHQXIwl9T8YUaYNkICmd902QGsiMIuOKHMpy2WFVTlqVsicSFkNp+55MnpyRrN4S8Y3M5rkuj/0H
                        egsDz76T5SLAyb945NIwjD891UqUQ7u1vmOfNvoGdeU9N2tjxxtaUKfzntG/T6y2rdUQ1AP1z3v1
                        QQNQY616zmrJ5bony3V35jRGNAp9TT2js2NVJc1UjNHpJNVAzL27t923/D0B8/n06VKxHGUTJ0je
                        ektmkqIsv/dOmX/zDUmnpyQuRDKvyAtJJgvWSpbl0kpzaaa5WOdEOlkiubWSOSuAeM7J9jSTHySZ
                        +CCiiPE9aVqV2sS8iEFShG5f4y7v6FM/EXD80S880NfV+8uzzbrj0F6tjs5r1x2f0HzsmNZ+vE+D
                        UkEVVFD1ba65qvq+ak9RtK8kCk7T3GpurWYuVwFlcRoVDaHznariVMU32qi1NW+01fONzmWZWxbo
                        rx255Zr7zjL55wNqkl5LEKKjR6V9aIRs5RqiAkx8dzt+HHeiS8dCWgqt3HLP+m4G+yKMGN4cbbDr
                        ZAPfCIKg52WiAgGCLH7u/K6kQKPaoLergD37vQm3AP9ykYIaF36uneWOsRFdaIpmpaJOv7xdPWPU
                        +EYRWVJFnWop9LWnXFAQbSWZblkV642DXZpaVQXtSIVa51RVNZBF9c4qCYox2lpI1CWZGs9o4qxT
                        cXdepOCZP3nsFwpBeGN9ekLd9BS11Gf68EF6i2CiEF3SA3LniOOQL/3SbawoCq12QpblzM7MsDad
                        4pKuXkSE3FqctZRKRV44Wsdr50t77gCnHTlTq6QLCVFvmXqS0+MnNx/5xJZPXr5994tLgNnU+FDl
                        sstVqxPqGm0aCwmh5wiCEBEQMUtbI85RiAKu33ojseTUqpPkrTrLlvVTKpWYGh8nCgyVSoVKb4X+
                        vh52zOwiPFVd8syzk0XgLLUUohib1IjEF5vI0AUKGk+a6iw6OSZZBpm1RAWDipzn5oIqlDG0mm2+
                        8lfPUY4jnCq1Wp27t3yMGzd9nGpL+ddXhxnos/jBPKqjnJycZ9opidNOsaYjnwioCFlqIYwAcBh1
                        fql5IWDfihvc8UMq1UmseoDDN4KRzg1/tlIkAse6DQUXsHb1JYTiCP2Yel+FcrFA2k7o7eth3cYr
                        iX2fRquJVQGZZXhZFzO5o1fPNRUAIuCyHDEeRgxgiczCDcDXz23x269e366eEfUDnEs7VyiGs3e0
                        AJ4qdQ+Gl/lUNOQ377+bQs8Ktu95lcsv24BfPc3EyB6Ghtaz6dYhDp44zNYNW6BdY/TJv+b69QPU
                        956ku5WixixqeDbrFDVeR01nsWnrhguyWJq1/4riQsezDOrJkquc52eqMWgJUV9Em622zmeBvrR3
                        vx6ZaSh+QT2cmqCow9VMv/XGDp23ZW22Em22Ev3bf39b2422+sYs+ul504iKZxRVDcXT3AavXADo
                        XXPjiHT1iForXmDEiIgqonSmgCwo8lo7l+mWlcGegvieEZk9LI996lOypZjK7NiwEJZk7vQhuUIn
                        5av33S/B/GFRlwsgxSCQqkMSp+JUl2KrqnhhIBgj6qwgRkxUHrkAUM+MrZTGPBijQeRp5HvqnHau
                        TtFQRKdV9am5ln7rwJwOFFVr9ZoqohY0Dox2F0Lt761odzHSUBON4lBFRNMsU5tbzUX0z1qpHsis
                        houeKqDinIblUmennFODw3PNlRckSXLs4M62ZwQxmBgKhZD5pNnxVYFEYbXn8ceVAo/Mt/jm6yf4
                        /pF/4JlHf4vRUxO8uH0XvjmX77kd47KVp7jlug18+S+fY2x6jt8oRlzpGZb5hrONnwLGOcK+HlyS
                        IAgLSSatanPnBYBD//njfzx+17V/VFR3eRtHd6VA7VSj84yxaDSKcl0U8Fg3fGmmTjPN6C4X2Hd4
                        lOe+89o7+w6uW3cZd267mtNTVVrWscYz3BoH1FSXEgRVotAnGugjnxyn6HtUW3LgqoPH/umiWuz3
                        DeyN56bXLVhLsb9APBGQZ47AdBCtdALeFAd8safIn7dTvvD0N5irNVnVU+b+2zbgnBL6HvtPTLPz
                        yBR/+uy3MQoPliIu8w11Vdx52UuWU1q9Agk8XLNBFEUEcbTnXZuFbLa606jej+L8kkf/QBdnJqsQ
                        eoh0/MYKGFV+vhgx45Rn9hwC4Pc/cz0P3L6RJM0JPMPNG1cxPPYDvrdjH2EccXepwJBvaOn5N74S
                        idJ1xRrs9DSCYJyarFnfea40nN8sgDl2+6aXK0GwrZ7nkML48DgLxhLGQSeXF30xBFJV/qbR5vlW
                        xl1bBukrRlinGIEoDti19ySlmTrlwOfBQsiawJDruYWlnbBs/RrKV6yhfXA/3UHI1MzCK5v2H//Z
                        xXJ9oYICbjQKH/fhO6I4iQ3LVvcyfmwS51u8wOuUJ4UMJRT4XDmirfDCmyO0nVuqtTmwOg74aneB
                        Xs+jJZDreTU4zaj0ddO96QqSkcN4YhAVkyTRY2fhLrCZJcju+JXpVnq84nsmt06C/oIsX9UrppWL
                        zdxZ1xFAUkUMIg+VQumKfIkKoUSFUPw4lEohlBtCX4yIZIs+utQjpLmUipH03bJV0jOnxC4sSCUI
                        zEw7OzIua159h2gXj7evGfz4QKX4vcj31zatc54Rkok60+Nz5IHBBB4slhcDOFW+XGuxJ3P0GKGl
                        ys2Bx8OlqFN/AFlsOiRJ6OrtYuDWrdi5Ktn4aUpRZGpzrcNnxubuvG129sT7AgIM33HVzyz3ZGdq
                        HS2r6nkGV2sxNz7HQpqjvod4sgTZcI5J6ygt3qcRUJbFqwDEWgIcPUOr6d68gWxinHxqkmIciSaW
                        kZH6tbdOnd590Y7yHmN42/onejzziBd4fiN3zvM9jHW0q00ac02SNMeJgBE8AaNgnaIozoF1DuMc
                        gW8oLe+j+4o1+F0l0pOj2OYC5TA0WZpncwv5k5v3jTz5bgzvfzZz1eBNK3qKz5fC4GPzWY4i6vkG
                        rJI3E5JmQpZk5LlFrcNpRzLjeQTFmLi/h3h5P14xxs7Pks/MIGKkJ/SZmW2fOD42+8Cd1eqOn7T+
                        Bzrd2r9tw+YujxdiMYPOExq5U2NEjedhzOI2Orf4BAISBEixiPg+6hx2oYlrNHGqUg4CIXe0Mnt8
                        fKz+yW1nzgy/19of+PjtaSjdc+Vl93RVCo8vL0Sb29bRco7EdbpP6TwXnPuDc2huASXyPCl4HhHC
                        qerCntnZ1uMvjo2/9AQsvN+6P/UJ60tXX1JaF1eeyDO7UQLZuqwYDwAdpYBElUgEDxAjOKucmW9N
                        GeFNY2T/v7098ujD0Pqg632oM+rvruzqX7v60odsmg/V2llWKoXr4sC/slZP96VZfri/OwrTtjuy
                        e+/03/0iteqHWesjGzu5tPhRxvtvrL2iClFhHX8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQt
                        MjlUMDc6MTM6MjYrMDM6MDCmacO7AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTI5VDA3OjEz
                        OjI2KzAzOjAw1zR7BwAAAABJRU5ErkJggg==" />
                      </svg>

                      )}
                      icon2={(
                        <svg width="128" height="61" viewBox="0 0 128 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_235_531" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="61">
                            <rect width="128" height="61" fill="#C4C4C4"/>
                            </mask>
                            <g mask="url(#mask0_235_531)">
                            <path d="M18 32.9412C18 32.9412 29.4133 43.5364 49.2188 42.9787C69.0242 42.4211 78.1996 27.2533 84.4657 20.3386C90.7319 13.4238 100.802 7.51285 112.44 13.4238C117.139 15.7659 123.293 23.5729 129 31.9375" stroke="url(#paint0_linear_235_531)" stroke-width="2" stroke-miterlimit="10"/>
                            </g>
                            <defs>
                            <linearGradient id="paint0_linear_235_531" x1="129.56" y1="36.1755" x2="17.666" y2="31.1237" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF4D5E"/>
                            <stop offset="1" stop-color="#FF4D5E" stop-opacity="0"/>
                            </linearGradient>
                            </defs>
                        </svg>
                      )}
                      title="Rewards ($RED)"
                      count={rewardDebt && parseFloat(formatUnits(rewardDebt, 9)).toFixed(2)}
                      percentage={{
                        label: (
                          <MDBox textAlign="center" width="100%" ml="100%">
                            <MDTypography variant="h6" color="primary">
                              RED rewards per day:{" "}
                              {formatter.format(
                                sCliffInfo && totalSupplyF > 0
                                  ? parseFloat(
                                      (rewardPerBlockF * 6200 * formatUnits(sCliffInfo, 18)) /
                                        totalSupplyF
                                    ).toFixed(2)
                                  : 0
                              )}
                            </MDTypography>
                            <MDButton
                              color="primary"
                              width="60%"
                              backgroundColor="white"
                              textTransform="none"
                              onClick={() => depositCLIFF(0)}
                              display="inline-block"
                              disabled={
                                rewardDebt
                                  ? !(parseFloat(formatUnits(rewardDebt, 9)).toFixed(0) > 0)
                                  : true
                              }
                            >
                              Claim $RED
                            </MDButton>
                          </MDBox>
                        ),
                      }}
                    />
                  </MDBox>
                </Grid>
            <Grid item xs={12} md={6} lg={12} className="stakeCard">
              <Card className="stakesSec">
                <MDBox
                  mx={2}
                  py={4}
                  px={2}                
                  borderRadius="lg"         
                  textAlign="center"  
                  // sx={{background: "#75DCAD",
                  //   opacity: "0.25",
                  //   filter: "blur(200px)"}}     
                >
                  <svg width="146" height="82" viewBox="0 0 146 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M139.574 8.53711C139.574 8.53711 130.505 29.5888 127.483 36.6061C124.892 42.6517 119.386 55.5526 117.011 60.8425C114.636 66.1324 114.42 71.3144 107.726 76.3344C101.033 81.4084 97.2545 81.0305 81.8706 81.1385C66.4866 81.2465 26.0565 81.7323 16.0165 81.9482C6.03044 82.1641 4.03322 81.7323 2.62977 80.1669C1.49622 78.9794 -0.447013 76.9821 0.092774 73.2576C-0.0151835 74.4451 0.0927744 78.6015 6.51624 78.4935C14.0193 78.3856 85.0013 77.414 92.0726 77.0901C99.1438 76.7122 106.323 75.4168 109.832 67.8058C113.34 60.1408 135.579 8.69905 135.579 8.69905C138.116 8.59109 139.574 8.53711 139.574 8.53711Z" fill="#CC8375"/>
                    <path d="M135.58 8.75293C135.58 8.75293 113.34 60.2486 109.832 67.8596C106.323 75.5246 99.1441 76.8201 92.0728 77.144C85.0016 77.5218 14.0196 78.4395 6.51654 78.5474C0.0930659 78.6554 -0.0148922 74.499 0.0930653 73.3115C0.0930653 73.0956 0.147044 72.9876 0.147044 72.9876C0.30898 72.124 0.578874 71.1524 1.0107 70.0728C3.43975 64.2431 21.6846 28.7251 23.7897 24.2988C25.8949 19.8186 31.5627 14.7985 38.364 14.4207C44.3556 14.0968 117.551 9.8325 135.58 8.75293Z" fill="#F8D7BC"/>
                    <path d="M97.9564 47.9951C98.6041 47.9951 99.3058 48.1571 99.7916 48.6429C100.331 49.1287 100.763 49.9923 100.331 51.4498C99.4138 54.3106 97.6325 56.7397 92.6664 56.9556C87.7544 57.1715 30.4829 59.1147 27.892 59.1147C25.301 59.1147 24.5993 57.4954 24.6532 56.1459C24.7612 54.5265 26.6504 51.0179 31.4546 50.748C35.6109 50.3702 91.317 48.211 97.9564 47.9951Z" fill="#E5B39E"/>
                    <path d="M32.9658 29.265C33.6675 28.1315 34.6931 26.0803 37.392 25.9183C41.0086 25.6484 43.3837 25.6484 45.4888 25.6484C47.648 25.6484 48.5656 26.836 47.648 28.6712C46.7303 30.5605 45.4349 33.2594 44.7331 34.8248C44.0314 36.3362 43.0058 38.0096 39.3892 38.2255C35.7727 38.4414 33.8834 38.2255 31.9402 38.2255C29.9969 38.2255 29.1333 37.1999 29.781 35.6345C30.4288 34.1231 31.8862 31.0463 32.9658 29.265Z" fill="#E5B39E"/>
                    <path d="M144.162 10.4802C142.975 13.0712 141.085 17.8213 139.844 20.1424C138.602 22.5175 136.281 27.5915 129.75 28.6711C123.218 29.7506 115.715 29.6427 112.963 29.5887C111.073 29.5887 109.616 29.0489 108.752 28.2392C108.266 27.8074 107.511 26.8898 107.133 25.7562C106.863 25.0545 106.755 24.2448 106.917 23.3811C107.241 21.276 111.559 11.6138 112.207 10.2103C112.207 10.2103 113.07 8.10516 114.528 5.94601C115.931 3.73288 118.144 2.05954 121.221 1.30384C124.298 0.548138 130.506 0.116308 133.798 0.0623293C137.091 0.00835054 139.196 -0.0456282 140.87 0.0623293C141.733 0.116308 142.921 0.656095 143.784 1.84363C143.946 2.00556 144.054 2.22148 144.216 2.43739C145.512 4.81246 145.404 7.88925 144.162 10.4802Z" fill="#75DCAD"/>
                    <path d="M144.162 10.4801C142.975 13.0711 141.085 17.8212 139.844 20.1423C138.602 22.5174 136.281 27.5914 129.75 28.671C123.218 29.7505 115.715 29.6426 112.963 29.5886C111.073 29.5886 109.616 29.0488 108.752 28.2391C108.266 27.8073 107.511 26.8897 107.133 25.7561C113.934 28.2391 127.051 26.4578 130.937 25.2703C135.04 24.0288 137.523 19.1707 138.98 16.0939C140.438 13.0171 141.301 10.858 142.813 6.64764C143.514 4.65042 143.73 2.97708 143.73 1.78955C143.892 1.95149 144 2.1674 144.162 2.38332C145.512 4.81236 145.404 7.88915 144.162 10.4801Z" fill="#25A66E"/>
                    <path d="M133.636 12.7475L132.935 11.56C132.935 11.56 132.773 11.7759 132.611 12.0458C132.449 11.9918 132.233 11.9378 132.017 11.9378L129.102 11.8299L130.128 9.13094C130.398 8.4832 130.344 7.83545 130.02 7.40362L129.264 6.16211L129.102 6.75588C128.886 6.7019 128.67 6.64792 128.4 6.59394C126.943 6.53996 125.27 7.61954 124.784 8.969L123.758 11.6679L120.843 11.56C119.386 11.506 117.767 12.5316 117.227 13.935C117.173 14.043 117.173 14.2049 117.119 14.3129L116.579 14.4209L117.227 15.5544C117.497 16.0942 118.091 16.472 118.9 16.472L121.815 16.58L121.059 18.5772L121.005 18.5232L120.088 19.6028L120.897 21.0063C121.221 21.4921 121.761 21.8159 122.517 21.8159C123.974 21.8699 125.594 20.7903 126.133 19.4409L127.159 16.7419L130.074 16.8499C131.531 16.9039 133.205 15.8243 133.69 14.4748C133.96 13.8271 133.906 13.1793 133.636 12.7475Z" fill="#208959"/>
                    <path d="M133.042 13.3951C132.502 14.7446 130.883 15.8241 129.425 15.7702L126.511 15.6622L125.485 18.3611C124.945 19.7106 123.326 20.7902 121.868 20.7362C120.411 20.6822 119.601 19.4947 120.141 18.1452L121.167 15.4463L118.252 15.3383C116.794 15.2843 115.985 14.0968 116.524 12.7473C117.064 11.3979 118.684 10.3183 120.141 10.3723L123.056 10.4802L124.082 7.7813C124.621 6.43183 126.241 5.35226 127.698 5.40624C129.156 5.46022 129.965 6.64775 129.425 7.99722L128.4 10.6962L131.315 10.8041C132.826 10.8581 133.582 11.9916 133.042 13.3951Z" fill="#FAFAFA"/>
                    <path d="M97.0384 46.4302C97.6861 46.4302 98.3879 46.5921 98.8737 47.0779C99.4135 47.5637 99.8453 48.4274 99.4135 49.8848C98.4958 52.7457 96.7145 55.1747 91.7485 55.3906C86.7824 55.6066 29.565 57.5498 26.974 57.5498C24.383 57.5498 23.6813 55.9304 23.7353 54.581C23.8432 52.9616 25.7325 49.453 30.5366 49.1831C34.6929 48.8052 90.399 46.6461 97.0384 46.4302Z" fill="#FEFEFE"/>
                    <path d="M32.372 27.5917C33.0737 26.4581 34.0993 24.4069 36.7983 24.245C40.4148 23.9751 42.7899 23.9751 44.8951 23.9751C47.0542 23.9751 47.9719 25.1626 47.0542 26.9979C46.1366 28.8872 44.8411 31.5861 44.1394 33.1515C43.4377 34.7169 42.4121 36.3362 38.7955 36.5521C35.1789 36.7681 33.2897 36.5521 31.3464 36.5521C29.4032 36.5521 28.5395 35.5265 29.1873 33.9612C29.835 32.4498 31.2924 29.4269 32.372 27.5917Z" fill="#FEFEFE"/>
                  </svg>
                  <MDTypography variant="h4" mt={2} color="white">
                    $CLIFF Staking
                  </MDTypography>
                </MDBox>
                {/*  <MDBox my={3} mx={3}>
                  <MDTypography variant="body2" fontWeight="light" textAlign="center" size="small">
                    Keep in mind 1 sCLIFF = approx 1.6 CLIFF. Do not be alarmed when you notice your
                    sCLIFF balance is less than your CLIFF balance. Whenever you unstake/withdraw
                    (including rewards), you will have your original token balance you staked +
                    rewards 🙂
                  </MDTypography>
                  <Divider />
                </MDBox> */}
                <MDBox pt={1}>
                  <MDBox py={0} textAlign="center">
                    {disabled ? (
                      <>
                        <MDTypography variant="h6" color="white">
                          Transaction pending!
                        </MDTypography>
                        <CircularProgress color="primary" size={26} />
                      </>
                    ) : (
                      <>
                        <MDTypography variant="h6" color="error">
                          {approvalError}
                        </MDTypography>
                        <div className="clifBalance">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xmlSpace="preserve">  <image id="image0" width="40" height="40" x="0" y="0"
                      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                        AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
                        CXBIWXMAAAsTAAALEwEAmpwYAAAMsElEQVRYw+2YaYyd1XnHf89517vM3NlsjB0z8RjjBQOGgsEs
                        oVRAUZYiglBVlW4qUtt8aJugRApNWUrVtFBVrfKBSm1JE6lIDWqbqhSVJEoaQ1mMDY7teLyOPfbY
                        49nuzNxl7n2Xc55+uOOxjQmQQvupRzq6m+5zfu//vM//ed4D/z8+3JAPG2D6K7+3Mdi3a23NDxa8
                        VUPrvOMHt7Znq2+YUvlId1e51JiYPLzqh/sO/J8Cnv7879zeFfubG6WuW2T06H3F4wfiLIwJoxga
                        NRTIBALjUW+3Wyrmn8tR+Np8M9kzuH3vy/9rgPUH71qerr36d02p9w+KLic5+CPqJ45iURVEUKdq
                        DKKd2M6p+iJS9gyBERppTpa5J6UcfG3lf+ye+kgBzzz2xWcDP7i30NXbNzczRbbvdTVTp1XFJ2/l
                        pO2MPLOoKsYY/NAjKAT4cQiewamKh0jFM7RhppXabw9u3/fQhwbc+elLi4M3/MozPZWBX51NUtrH
                        DygHf0Q6Ncf8bEZ1rk0jycmcA1EEMAK+QOQLpUJApa9Eub+MV4hQVSJBKp7HzEL69SOnFj53x+ho
                        +38EOP6Hn7+jFJef8Qql9dXavLJ/p2b79zMz3uL0XE5LHXEkxKEQ+oLnCSKdkFYht448s5BZioGh
                        f3k35RUVJPBRp9Lre5Irw/OZ/vbQy3u3/1SA+x7YFC7bfO+B7krfmqmpCeWt7dR2H+L0rDCVWgpF
                        KMRC3QhqDAbwRDB01PPpzADwUZLUkiUZPYWQ/sF+gnIBZ5VlgSczKUdGXu7deAc/zD8Q4PjDD5b8
                        7lXfLHb1frZanXb6xveZfn2Y+sAQSQRy+jgUQp7KHEcdmMVAZnF6i5ABEIsQAJ8JhJs8w0I7o4Cy
                        fHCAoKeEyx29vm9qmX1+slX99et3jS+cz2LeDdCWV36jt9L/2Wq9ru7tV2T69WFprNoovduuFH/m
                        lHQXIwl9T8YUaYNkICmd902QGsiMIuOKHMpy2WFVTlqVsicSFkNp+55MnpyRrN4S8Y3M5rkuj/0H
                        egsDz76T5SLAyb945NIwjD891UqUQ7u1vmOfNvoGdeU9N2tjxxtaUKfzntG/T6y2rdUQ1AP1z3v1
                        QQNQY616zmrJ5bony3V35jRGNAp9TT2js2NVJc1UjNHpJNVAzL27t923/D0B8/n06VKxHGUTJ0je
                        ektmkqIsv/dOmX/zDUmnpyQuRDKvyAtJJgvWSpbl0kpzaaa5WOdEOlkiubWSOSuAeM7J9jSTHySZ
                        +CCiiPE9aVqV2sS8iEFShG5f4y7v6FM/EXD80S880NfV+8uzzbrj0F6tjs5r1x2f0HzsmNZ+vE+D
                        UkEVVFD1ba65qvq+ak9RtK8kCk7T3GpurWYuVwFlcRoVDaHznariVMU32qi1NW+01fONzmWZWxbo
                        rx255Zr7zjL55wNqkl5LEKKjR6V9aIRs5RqiAkx8dzt+HHeiS8dCWgqt3HLP+m4G+yKMGN4cbbDr
                        ZAPfCIKg52WiAgGCLH7u/K6kQKPaoLergD37vQm3AP9ykYIaF36uneWOsRFdaIpmpaJOv7xdPWPU
                        +EYRWVJFnWop9LWnXFAQbSWZblkV642DXZpaVQXtSIVa51RVNZBF9c4qCYox2lpI1CWZGs9o4qxT
                        cXdepOCZP3nsFwpBeGN9ekLd9BS11Gf68EF6i2CiEF3SA3LniOOQL/3SbawoCq12QpblzM7MsDad
                        4pKuXkSE3FqctZRKRV44Wsdr50t77gCnHTlTq6QLCVFvmXqS0+MnNx/5xJZPXr5994tLgNnU+FDl
                        sstVqxPqGm0aCwmh5wiCEBEQMUtbI85RiAKu33ojseTUqpPkrTrLlvVTKpWYGh8nCgyVSoVKb4X+
                        vh52zOwiPFVd8syzk0XgLLUUohib1IjEF5vI0AUKGk+a6iw6OSZZBpm1RAWDipzn5oIqlDG0mm2+
                        8lfPUY4jnCq1Wp27t3yMGzd9nGpL+ddXhxnos/jBPKqjnJycZ9opidNOsaYjnwioCFlqIYwAcBh1
                        fql5IWDfihvc8UMq1UmseoDDN4KRzg1/tlIkAse6DQUXsHb1JYTiCP2Yel+FcrFA2k7o7eth3cYr
                        iX2fRquJVQGZZXhZFzO5o1fPNRUAIuCyHDEeRgxgiczCDcDXz23x269e366eEfUDnEs7VyiGs3e0
                        AJ4qdQ+Gl/lUNOQ377+bQs8Ktu95lcsv24BfPc3EyB6Ghtaz6dYhDp44zNYNW6BdY/TJv+b69QPU
                        956ku5WixixqeDbrFDVeR01nsWnrhguyWJq1/4riQsezDOrJkquc52eqMWgJUV9Em622zmeBvrR3
                        vx6ZaSh+QT2cmqCow9VMv/XGDp23ZW22Em22Ev3bf39b2422+sYs+ul504iKZxRVDcXT3AavXADo
                        XXPjiHT1iForXmDEiIgqonSmgCwo8lo7l+mWlcGegvieEZk9LI996lOypZjK7NiwEJZk7vQhuUIn
                        5av33S/B/GFRlwsgxSCQqkMSp+JUl2KrqnhhIBgj6qwgRkxUHrkAUM+MrZTGPBijQeRp5HvqnHau
                        TtFQRKdV9am5ln7rwJwOFFVr9ZoqohY0Dox2F0Lt761odzHSUBON4lBFRNMsU5tbzUX0z1qpHsis
                        houeKqDinIblUmennFODw3PNlRckSXLs4M62ZwQxmBgKhZD5pNnxVYFEYbXn8ceVAo/Mt/jm6yf4
                        /pF/4JlHf4vRUxO8uH0XvjmX77kd47KVp7jlug18+S+fY2x6jt8oRlzpGZb5hrONnwLGOcK+HlyS
                        IAgLSSatanPnBYBD//njfzx+17V/VFR3eRtHd6VA7VSj84yxaDSKcl0U8Fg3fGmmTjPN6C4X2Hd4
                        lOe+89o7+w6uW3cZd267mtNTVVrWscYz3BoH1FSXEgRVotAnGugjnxyn6HtUW3LgqoPH/umiWuz3
                        DeyN56bXLVhLsb9APBGQZ47AdBCtdALeFAd8safIn7dTvvD0N5irNVnVU+b+2zbgnBL6HvtPTLPz
                        yBR/+uy3MQoPliIu8w11Vdx52UuWU1q9Agk8XLNBFEUEcbTnXZuFbLa606jej+L8kkf/QBdnJqsQ
                        eoh0/MYKGFV+vhgx45Rn9hwC4Pc/cz0P3L6RJM0JPMPNG1cxPPYDvrdjH2EccXepwJBvaOn5N74S
                        idJ1xRrs9DSCYJyarFnfea40nN8sgDl2+6aXK0GwrZ7nkML48DgLxhLGQSeXF30xBFJV/qbR5vlW
                        xl1bBukrRlinGIEoDti19ySlmTrlwOfBQsiawJDruYWlnbBs/RrKV6yhfXA/3UHI1MzCK5v2H//Z
                        xXJ9oYICbjQKH/fhO6I4iQ3LVvcyfmwS51u8wOuUJ4UMJRT4XDmirfDCmyO0nVuqtTmwOg74aneB
                        Xs+jJZDreTU4zaj0ddO96QqSkcN4YhAVkyTRY2fhLrCZJcju+JXpVnq84nsmt06C/oIsX9UrppWL
                        zdxZ1xFAUkUMIg+VQumKfIkKoUSFUPw4lEohlBtCX4yIZIs+utQjpLmUipH03bJV0jOnxC4sSCUI
                        zEw7OzIua159h2gXj7evGfz4QKX4vcj31zatc54Rkok60+Nz5IHBBB4slhcDOFW+XGuxJ3P0GKGl
                        ys2Bx8OlqFN/AFlsOiRJ6OrtYuDWrdi5Ktn4aUpRZGpzrcNnxubuvG129sT7AgIM33HVzyz3ZGdq
                        HS2r6nkGV2sxNz7HQpqjvod4sgTZcI5J6ygt3qcRUJbFqwDEWgIcPUOr6d68gWxinHxqkmIciSaW
                        kZH6tbdOnd590Y7yHmN42/onejzziBd4fiN3zvM9jHW0q00ac02SNMeJgBE8AaNgnaIozoF1DuMc
                        gW8oLe+j+4o1+F0l0pOj2OYC5TA0WZpncwv5k5v3jTz5bgzvfzZz1eBNK3qKz5fC4GPzWY4i6vkG
                        rJI3E5JmQpZk5LlFrcNpRzLjeQTFmLi/h3h5P14xxs7Pks/MIGKkJ/SZmW2fOD42+8Cd1eqOn7T+
                        Bzrd2r9tw+YujxdiMYPOExq5U2NEjedhzOI2Orf4BAISBEixiPg+6hx2oYlrNHGqUg4CIXe0Mnt8
                        fKz+yW1nzgy/19of+PjtaSjdc+Vl93RVCo8vL0Sb29bRco7EdbpP6TwXnPuDc2huASXyPCl4HhHC
                        qerCntnZ1uMvjo2/9AQsvN+6P/UJ60tXX1JaF1eeyDO7UQLZuqwYDwAdpYBElUgEDxAjOKucmW9N
                        GeFNY2T/v7098ujD0Pqg632oM+rvruzqX7v60odsmg/V2llWKoXr4sC/slZP96VZfri/OwrTtjuy
                        e+/03/0iteqHWesjGzu5tPhRxvtvrL2iClFhHX8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQt
                        MjlUMDc6MTM6MjYrMDM6MDCmacO7AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTI5VDA3OjEz
                        OjI2KzAzOjAw1zR7BwAAAABJRU5ErkJggg==" />
                      </svg>
                          <span>Cliff Balance:{" "}</span>&nbsp;&nbsp;
                          <div>{cliffTokenBalance
                              ? formatter.format(formatUnits(cliffTokenBalance, 18))
                              : 0}</div>
                        </div>
                        <form className="cliffAmtInpSec">
                          <input
                              type= "text"
                              pattern= "[0-9]*"
                              className="cliffAmtInp"
                            placeholder="Enter CLIFF Amount"
                            value={stakingCLIFFAmount > 0 ? stakingCLIFFAmount : ""}
                            onChange={({ currentTarget }) => {
                              setStakingCLIFFAmount(Math.floor(parseFloat(currentTarget.value)));
                            }}
                            onFocus={()=>{
                              document.getElementsByClassName('cliffAmtInpSec')[0].style.border='1px solid white'
                            }}
                            onBlur={()=>{
                              document.getElementsByClassName('cliffAmtInpSec')[0].style.border='1px solid #1F1F1F'
                            }}
                            id="eth"
                          />
                          <button className="cliffMaxBtn" type="button" onClick={() => {
                            setStakingCLIFFAmount(Math.floor(formatUnits(cliffTokenBalance, 18)));
                          }}>
                            Maximum
                          </button>
                        </form>
                        {/* <div className="clifBalance">
                          
                          <span>Estimated RED rewards per day:{" "}</span>&nbsp;&nbsp;
                          <div>{formatter.format(
                            totalSupplyF > 0
                              ? parseFloat(
                                  (rewardPerBlockF * 6200 * stakingCLIFFAmount) / totalSupplyF
                                ).toFixed(2)
                              : 0
                          )}</div>
                        </div> */}

                        <p />
                      
                      </>
                    )}
                  </MDBox>

                  <MDBox  mt={'20px'} mb={'20px'} sx={{marginLeft:'auto',marginRight:"auto"}}  width="90%" display="flex" justifyContent="space-around">
                    <button
                    
                      onClick={() => approveCLIFF()}
                      disabled={disabled || disabledApproved}
                      className="approveBtn"
                    >
                      {disableMessage}
                    </button>
                    <button
                      className="stakeBtn"
                      onClick={() => depositCLIFF()}
                      disabled={disabled || !(stakingCLIFFAmount > 0) || !disabledApproved}
                    >
                      Stake $CLIFF
                    </button>
                  </MDBox>
                </MDBox>
                {/* <Divider /> */}
              </Card>
            </Grid>
          </Grid>
        </Grid>
          <MDBox pt={3} >
            <MDBox py={0} display="flex" bgColor="#080808" height="96px" sx={{border:"1px solid #1F1F1F",borderRadius:'8px',boxSizing:'border-box'}} alignItems="center" justifyContent="center">
              {disabled ? (
                <>
                  <MDTypography variant="h6" color="white">
                    Transaction pending!
                  </MDTypography>
                  <CircularProgress color="primary" size={26} />
                </>
              ) : (
                <>
                  <div className="clifUnBalance">
                        
                    <span>Cliff Staked:{" "}</span>&nbsp;&nbsp;
                    <div>{sCliffInfo ? parseFloat(formatUnits(sCliffInfo, 18)).toFixed(2) : 0}</div>
                  </div>
                  <div className="cliffUnAmtInpSec">
                    <input
                        type= "text"
                        pattern= "[0-9]*"
                        className="cliffAmtInp"
                      placeholder="Enter CLIFF Amount"
                      value={unStakingCLIFFAmount > 0 ? unStakingCLIFFAmount : ""}
                      onFocus={()=>{
                        document.getElementsByClassName('cliffUnAmtInpSec')[0].style.border='1px solid white'
                      }}
                      onBlur={()=>{
                        document.getElementsByClassName('cliffUnAmtInpSec')[0].style.border='1px solid #1F1F1F'
                      }}
                    onChange={({ currentTarget }) => {
                      setUnStakingCLIFFAmount(Math.floor(parseFloat(currentTarget.value)));
                    }}
                    
                    id="eth3"
                    
                    />
                    <button className="cliffMaxBtn" type="button" onClick={() => 
                      setUnStakingCLIFFAmount(
                        sCliffInfo ? Math.floor(formatUnits(sCliffInfo, 18)) : 0
                      )
                    }>
                      Maximum
                    </button>
                  </div>
                  <button className="unstakeBtn" disabled={disabled || !(unStakingCLIFFAmount > 0)} onClick={() => withdrawCLIFF()}>
                    Unstake
                  </button>
                </>
              )}
            </MDBox>
          </MDBox>
        {/* </Grid> */}
      </MDBox>

      {/* <Footer /> */}
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDTypography variant="info" display="inline-block">
          CONNECT FIRST to view your CLIFFORD Portfolio
        </MDTypography>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Portfolio;
