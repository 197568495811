import { formatUnits } from "@ethersproject/units";

// Soft UI Dashboard React components
// import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDBadge from "components/MDBadge";

import axios from "axios";

axios.defaults.headers.get["Content-Type"] = "application/x-www-form-urlencoded";

const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 12,
});

export async function portfolio(wallet) {
  try {
    /* return await axios.get(
      `https://api.covalenthq.com/v1/1/address/${wallet}` +
        `/portfolio_v2/?key=ckey_be4a9850cb1b4162b54bb8207d3`
    ); */
    return await axios.get(
      `https://effortless-leaf-336220.uc.r.appspot.com/portfolio_v2/${wallet}`
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
  return null;
}

export const getItemPortfolioPrice = (response, contract) => {
  let price = 0;
  response.data.data.data.items.map((item) => {
    if (item.contract_address === contract) {
      price += parseFloat(item.holdings[0].quote_rate ? item.holdings[0].quote_rate : 0);
    }
    return null;
  });
  return price;
};

export const getPortfolioPrice = (response, contractFilter, bool) => {
  // optrional contract, get only price for this contract
  // optional bool, if true we count only contractFilter
  let price = 0;
  response.data.data.data.items.map((item) => {
    if (
      (contractFilter && bool ? item.contract_address === contractFilter : true) &&
      (contractFilter && !bool ? item.contract_address !== contractFilter : true) &&
      item.holdings[0].close.balance > 0
    ) {
      price += parseFloat(item.holdings[0].close.quote || 0);
    }
    return null;
  });
  return price;
};

export const getPortfolioNumber = (response, contractFilter, bool) => {
  let number = 0;
  response.data.data.data.items.map((item) => {
    if (
      (contractFilter && bool ? item.contract_address === contractFilter : true) &&
      (contractFilter && !bool ? item.contract_address !== contractFilter : true) &&
      item.holdings[0].close.balance > 0
    ) {
      number += 1;
    }
    return null;
  });
  return number;
};
export const getPortfolioDataTable = (response) => {
  const datatable = [];
  response.data.data.data.items.map((item) => {
    if (item.holdings[0].close.balance > 0) {
      datatable.push({
        asset: (
          <MDTypography component="div" href="#" variant="caption" color="text" fontWeight="medium">
            {`${item.contract_name} (${item.contract_ticker_symbol})`}
          </MDTypography>
        ),
        price: (
          <MDTypography component="div" href="#" variant="caption" color="text" fontWeight="medium">
            {item.holdings[0].quote_rate
              ? formatterUS.format(parseFloat(item.holdings[0].quote_rate))
              : "#N/A"}
          </MDTypography>
        ),

        balance: (
          <MDTypography
            component="a"
            href="#"
            target="_blank"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {`${formatter.format(
              parseFloat(formatUnits(item.holdings[0].close.balance, item.contract_decimals))
            )} ${item.contract_ticker_symbol}`}
          </MDTypography>
        ),
        amountUSD: (
          <MDTypography component="div" href="#" variant="caption" color="text" fontWeight="medium">
            {item.holdings[0].close.quote
              ? formatterUS.format(parseFloat(item.holdings[0].close.quote).toFixed(2))
              : "#N/A"}
          </MDTypography>
        ),
      });
    }
    return null;
  });
  return datatable;
};
