/**
=========================================================
* Clifford Inu
=========================================================
*/

import { useEffect,useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import './styles.scss';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [open,setopen] = useState(true)

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () =>{
    if(open){
      setopen(false)
      setMiniSidenav(dispatch, true);
    } else {
      setopen(true)
      setMiniSidenav(dispatch,false)
    }
  } 

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center" paddingLeft="27px">
        <MDBox
          display={{ xs: "block" }}
          position="absolute"
          bottom={0}
          p={1.4}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <Divider
          variant="fullWidth"
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
          <svg className={`collapIcon ${open}`} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M10 17C10 16.4477 9.55228 16 9 16L5.8 16C4.94342 16 4.36113 15.9992 3.91104 15.9624C3.47262 15.9266 3.24842 15.8617 3.09202 15.782C2.71569 15.5903 2.40973 15.2843 2.21799 14.908C2.1383 14.7516 2.07337 14.5274 2.03755 14.089C2.00078 13.6389 2 13.0566 2 12.2L2 5.8C2 4.94342 2.00078 4.36113 2.03755 3.91104C2.07337 3.47262 2.1383 3.24842 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.24842 2.1383 3.47262 2.07337 3.91104 2.03755C4.36113 2.00078 4.94342 2 5.8 2L9 2C9.55228 2 10 1.55228 10 1C10 0.447716 9.55228 6.28192e-07 9 6.04051e-07L5.75868 4.62368e-07C4.95372 -1.29243e-05 4.28936 -2.24896e-05 3.74817 0.0441936C3.18608 0.0901188 2.66937 0.188683 2.18404 0.435974C1.43139 0.819468 0.819468 1.43139 0.435974 2.18404C0.188684 2.66937 0.0901194 3.18608 0.0441942 3.74817C-2.38419e-05 4.28937 -1.33514e-05 4.95374 0 5.75872V12.2413C-1.33514e-05 13.0463 -2.38419e-05 13.7106 0.0441933 14.2518C0.0901184 14.8139 0.188683 15.3306 0.435974 15.816C0.819468 16.5686 1.43139 17.1805 2.18404 17.564C2.66937 17.8113 3.18608 17.9099 3.74817 17.9558C4.28937 18 4.95373 18 5.7587 18H9C9.55228 18 10 17.5523 10 17Z" fill="#FFFDF5"/>
                <path d="M9.70711 11.2929C10.0976 11.6834 10.0976 12.3166 9.70711 12.7071C9.31658 13.0976 8.68342 13.0976 8.29289 12.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289L8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289C10.0976 5.68342 10.0976 6.31658 9.70711 6.70711L8.41421 8H17C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H8.41421L9.70711 11.2929Z" fill="#FFFDF5"/>
              </svg>
          {/* <SidenavCollapse
            name={"Collapse"}
            icon={(
              <svg className="collapIcon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M10 17C10 16.4477 9.55228 16 9 16L5.8 16C4.94342 16 4.36113 15.9992 3.91104 15.9624C3.47262 15.9266 3.24842 15.8617 3.09202 15.782C2.71569 15.5903 2.40973 15.2843 2.21799 14.908C2.1383 14.7516 2.07337 14.5274 2.03755 14.089C2.00078 13.6389 2 13.0566 2 12.2L2 5.8C2 4.94342 2.00078 4.36113 2.03755 3.91104C2.07337 3.47262 2.1383 3.24842 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.24842 2.1383 3.47262 2.07337 3.91104 2.03755C4.36113 2.00078 4.94342 2 5.8 2L9 2C9.55228 2 10 1.55228 10 1C10 0.447716 9.55228 6.28192e-07 9 6.04051e-07L5.75868 4.62368e-07C4.95372 -1.29243e-05 4.28936 -2.24896e-05 3.74817 0.0441936C3.18608 0.0901188 2.66937 0.188683 2.18404 0.435974C1.43139 0.819468 0.819468 1.43139 0.435974 2.18404C0.188684 2.66937 0.0901194 3.18608 0.0441942 3.74817C-2.38419e-05 4.28937 -1.33514e-05 4.95374 0 5.75872V12.2413C-1.33514e-05 13.0463 -2.38419e-05 13.7106 0.0441933 14.2518C0.0901184 14.8139 0.188683 15.3306 0.435974 15.816C0.819468 16.5686 1.43139 17.1805 2.18404 17.564C2.66937 17.8113 3.18608 17.9099 3.74817 17.9558C4.28937 18 4.95373 18 5.7587 18H9C9.55228 18 10 17.5523 10 17Z" fill="#FFFDF5"/>
                <path d="M9.70711 11.2929C10.0976 11.6834 10.0976 12.3166 9.70711 12.7071C9.31658 13.0976 8.68342 13.0976 8.29289 12.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289L8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289C10.0976 5.68342 10.0976 6.31658 9.70711 6.70711L8.41421 8H17C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H8.41421L9.70711 11.2929Z" fill="#FFFDF5"/>
              </svg>
            )}
            // active={key === collapseName}
            // noCollapse={noCollapse}
          /> */}
         <span className={`collapseText ${open}`}>
            Collapse
          </span>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" className="logoImg" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List className="sideNavList">{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
