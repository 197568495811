/**
=========================================================
* Clifford Inu
=========================================================
*/

export default {
  defaultProps: {
    disableRipple: false,
  },
};
