/**
=========================================================
* Clifford Inu
=========================================================
*/

import React, { useState, useMemo } from "react";
import Grid from "@mui/material/Grid";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import './styles.scss'
// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
// Gradient on line https://blog.bitsrc.io/customizing-chart-js-in-react-2199fa81530a

function ReportsLineChart({
  color,
  title,
  description,
  // date,
  chart,
  chart2,
  button,
}) {
  const [dataSwitch, setDataSwitch] = useState(true);
  const { data, options } = dataSwitch
    ? configs(chart.labels || [], chart.datasets || {})
    : configs(chart2.labels || [], chart2.datasets || {});
  

  options['elements'] = {
    line: {
      tension: 1 
    }
  }
  options['bezierCurve'] = true
   console.log("options",options)

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="0rem">
        {useMemo(
          () => (
            <MDBox
              
              bgColor="#080808"
              borderRadius="lg"
              coloredShadow={color}
              px={2}
              pr={2}
              mt={0}
              pb={7}
              height="20rem"
            >
              <MDBox pt={1.5} pb={0} px={1}>
                <Grid container width="100%">
                  <Grid item xs={6} md={6} xl={6}>
                    <MDTypography variant="h5" textTransform="capitalize">
                      {title}
                    </MDTypography>
                    <MDTypography component="div" variant="button" color="text" fontWeight="bold">
                      {description}
                    </MDTypography>
                  </Grid>
                  {button && (
                    <Grid item xs={6} md={6} xl={6} align="right">
                      <button
                        className={dataSwitch === true ? "dayBtn active" : "dayBtn"}
                        onClick={() => {
                          setDataSwitch(true);
                        }}
                      >
                        7 Days
                      </button>
                      <button
                        className={dataSwitch === false ? "dayBtn active" : "dayBtn"}
                        onClick={() => {
                          setDataSwitch(false);
                        }}
                      >
                        30 Days
                      </button>
                    </Grid>
                  )}
                </Grid>

                {/* <Divider />
                <MDBox display="flex" alignItems="center">
                  <MDTypography
                    variant="button"
                    color="text"
                    lineHeight={1}
                    sx={{ mt: 0.15, mr: 0.5 }}
                  >
                    <Icon>schedule</Icon>
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="light">
                    {date}
                  </MDTypography>
                </MDBox> */}
              </MDBox>

              <Line data={data} options={options} />
            </MDBox>
          ),
          [chart, color, dataSwitch]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
  button: false,
  chart2: {},
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  chart2: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])),

  button: PropTypes.bool,
};

export default ReportsLineChart;
