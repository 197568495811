/**
=========================================================
* Clifford Inu
=========================================================
*/
import React, { useState, useEffect } from "react";
import './styles.scss'
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";
// utils
import { portfolio, getPortfolioNumber, getPortfolioPrice, getPortfolioDataTable } from "./utils";

// Contracts
const marketingWallet = "0x20c606fbb6984aaab0cad48f1f2644693adc1680";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function MarketingWallet() {
  const { columns } = {
    columns: [
      { Header: "Asset", accessor: "asset", align: "left" },
      { Header: "Balance", accessor: "balance", align: "left" },
      { Header: "Price", accessor: "price", align: "left" },
      { Header: "Value ($USD)", accessor: "amountUSD", align: "left" },
    ],
    // rows: [],
  };

  const [price, setPrice] = useState(0);
  const [priceLoader, setPriceLoader] = useState(0);
  // const [deadSupply, setDeadSupply] = useState(0);
  //  const [deadSupplyToday, setDeadSupplyToday] = useState(0);
  const [numberAssets, setNumberAssets] = useState(0);

  const [datatableData, setDatatableData] = useState([]);

  useEffect(() => {
    const fetchPortfolio = async (contract) => {
      setPriceLoader(1);
      setDatatableData([]);
      try {
        const response = await portfolio(contract);

        const number = getPortfolioNumber(response);
        const priceP = getPortfolioPrice(response);
        const datatable = getPortfolioDataTable(response);

        setNumberAssets(number);
        setPrice(priceP);
        setDatatableData(datatable);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error.message);
      }
      setPriceLoader(0);
    };

    const refresh = async () => {
      await fetchPortfolio(marketingWallet);
    };

    refresh();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={(
                  <svg width="40" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0656 30C17.1769 30 22.1311 25.0458 22.1311 18.9344C22.1311 12.8231 17.1769 7.86885 11.0656 7.86885C4.95423 7.86885 0 12.8231 0 18.9344C0 25.0458 4.95423 30 11.0656 30Z" fill="#FFFDF5"/>
                    <path opacity="0.6" d="M18.9347 0C15.4921 0 12.2953 1.72131 10.3281 4.18033C10.574 4.18033 10.8199 4.18033 11.0658 4.18033C19.1806 4.18033 25.8199 10.8197 25.8199 18.9344C25.8199 19.1803 25.8199 19.4262 25.8199 19.6721C28.2789 17.7049 30.0003 14.5082 30.0003 11.0656C30.0003 4.91803 25.0822 0 18.9347 0Z" fill="#FFFDF5"/>
                  </svg>
                )}
                title="Number of Tokens"
                count={
                  !priceLoader ? (
                    <div>{formatter.format(numberAssets)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={(
                  <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_234_149)">
                    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M8.2 0H8.9V23.8H8.2C5.4 23.8 3.9 23.8 2.8 23.3C1.8 22.9 1.1 22.2 0.6 21.4C0 20.4 0 19.2 0 16.7V7.1C0 4.6 0 3.3 0.6 2.4C1.1 1.6 1.9 0.9 2.8 0.4C3.9 0 5.3 0 8.2 0Z" fill="#FFFDF5"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9998 16.7C33.9998 19.2 33.9998 20.5 33.3998 21.4C32.8998 22.2 32.0998 22.9 31.1998 23.3C30.0998 23.8 28.6998 23.8 25.7998 23.8H11.7998V0H25.7998C28.6998 0 30.0998 0 31.1998 0.5C32.1998 0.9 32.8998 1.6 33.3998 2.5C33.9998 3.4 33.9998 4.6 33.9998 7.1C33.9998 7.3 33.7998 7.4 33.6998 7.4H25.4998C22.6998 7.4 20.3998 9.4 20.3998 11.9C20.3998 14.4 22.6998 16.4 25.4998 16.4H33.6998C33.7998 16.4 33.9998 16.5 33.9998 16.7Z" fill="#FFFDF5"/>
                    <path d="M27.1998 11.9C27.1998 12.7 26.3998 13.4 25.4998 13.4C24.5998 13.4 23.7998 12.7 23.7998 11.9C23.7998 11.1 24.5998 10.4 25.4998 10.4C26.3998 10.4 27.1998 11.1 27.1998 11.9Z" fill="#FFFDF5"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_234_149">
                    <rect width="34" height="23.8" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                )}
                title="Tokens Wallet ($USD)"
                count={
                  !priceLoader ? (
                    <div>{formatterUS.format(price)}</div>
                  ) : (
                    <CircularProgress size={26} />
                  )
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="tokenTable">
              <MDBox
                mx={2}
                py={1}
                px={2}
                borderRadius="lg"
                bgColor="#080808"
              >
                <MDTypography  color="white">
                  Token Wallet Assets
                </MDTypography>
              </MDBox>
              <MDBox py={1} px={1}  bgColor="#080808">
                {!priceLoader ? (
                  <DataTable
                    table={{ columns, rows: datatableData }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder = {true}
                  />
                ) : (
                  <CircularProgress size={26} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default MarketingWallet;
