/**
=========================================================
* Clifford Inu
=========================================================
*/
import React from "react";

import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
// import TimelineItem from "examples/Timeline/TimelineItem";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { Link } from "react-router-dom";
// import ImageCard from "examples/Cards/InfoCards/ImageCard";

// import { openSeaCollections, getOpenSeaCollecionsAssetsNumber } from "../../../nft/utils";

// const deployerWallet = "0x81ed317154e4c6e829b0358f59c5578719e95ccb";
const formatter = new Intl.NumberFormat("en-US", {});
const formatterUS = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function TokensOverview({ tokensNumber, tokensTop, tokensPortoflioValue }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  return (
    <Card sx={{ height: "100%" }} className="tokenOverview">
      <MDBox pt={3} px={3}>
        <MDTypography variant="h4" fontWeight="medium">
          Tokens
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="primary"
                title="Dominant Token"
                description={
                  <MDBox position="relative" mb={0} width="100%">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgColor="primary"
                      color="white"
                      width="2rem"
                      height="2rem"
                      borderRadius="50%"
                      position="absolute"
                      top="8%"
                      left="2px"
                      zIndex={2}
                      mt={1}
                      ml={2}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                    >
                      <Avatar
                        alt={tokensTop[0] && `${tokensTop[0].address}`}
                        src={
                          tokensTop[0] &&
                          `https://raw.githubusercontent.com/dappradar/tokens/main/ethereum/${tokensTop[0].address}/logo.png`
                        }
                      />
                    </MDBox>
                    <Grid container spacing={1} minHeight="50px">
                      <Grid item xs={6} md={6} lg={6}>
                        <MDBox ml={8} pt={0} lineHeight={0} maxWidth="100%">
                          <MDTypography variant="button" fontWeight="medium" color="white">
                            {tokensTop[0] && tokensTop[0].symbol}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6} textAlign="right">
                        <MDBox mr={2} pt={0} lineHeight={0} alignItems="right">
                          <MDTypography
                            variant="button"
                            fontWeight="medium"
                            color="white"
                            alignItems="right"
                          >
                            {`${tokensTop[0] && formatterUS.format(tokensTop[0].currentPrice)} `}
                            {"("}
                            {tokensTop[0] &&
                              tokensPortoflioValue > 0 &&
                              formatter.format(
                                parseFloat((tokensTop[0].currentPrice / tokensPortoflioValue) * 100)
                              )}
                            {"%)"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <DefaultInfoCard
                color="primary"
                title="Tokens"
                description={<div>{formatter.format(tokensNumber)}</div>}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {tokensTop
            .filter((item, idx) => idx < 4) // Only top 4
            .map((item, idx) => (
              <Grid item xs={12} md={12} lg={12}>
                <MDBox position="relative" mb={0} width="100%">
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="primary"
                    color="white"
                    width="2rem"
                    height="2rem"
                    borderRadius="50%"
                    position="absolute"
                    top="8%"
                    left="2px"
                    zIndex={2}
                    mt={1}
                    ml={2}
                    sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                  >
                    <Avatar
                      alt={`${item.address}`}
                      src={`https://raw.githubusercontent.com/dappradar/tokens/main/ethereum/${item.address}/logo.png`}
                    />
                  </MDBox>
                  {/* L
                    EFT
                    */}
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={6} lg={6}>
                      <MDBox ml={8} pt={0} lineHeight={0} maxWidth="100%">
                        <MDTypography variant="button" fontWeight="medium" color="white">
                          {item.name}
                        </MDTypography>

                        <MDBox mt={1} mb={0} alignItems="right" width="100%">
                          <MDTypography variant="button" color="secondary" alignItems="right">
                            {`${formatter.format(item.balance)} ${item.symbol}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} textAlign="right">
                      {/* R
                   IGHT
                    */}
                      <MDBox mr={2} pt={0} lineHeight={0} alignItems="right">
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          color="white"
                          alignItems="right"
                        >
                          {`${formatterUS.format(item.currentPrice)} `}
                        </MDTypography>
                        <MDBox mt={1} mb={0} alignItems="right" width="100%">
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="light"
                            color={item.currentPrice >= item.lastDayPrice ? "success" : "error"}
                          >
                            {item.currentPrice >= item.lastDayPrice ? "+" : ""}
                            {item.lastDayPrice
                              ? parseFloat(
                                  (item.currentPrice / item.lastDayPrice - 1) * 100
                                ).toFixed(2)
                              : 0}
                            {"% ("}
                            {item.currentPrice >= item.lastDayPrice ? "+" : ""}

                            {item.lastDayPrice
                              ? formatterUS.format(
                                  parseFloat(item.currentPrice - item.lastDayPrice)
                                )
                              : 0}
                            {")"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>

                {idx < 3 && <Divider />}
                {/*    <ImageCard
                color="primary"
                // title="NFTS"
                // description={<div>{formatter.format(nftAssetsNumber)}</div>}
                img={`https://raw.githubusercontent.com/dappradar/tokens/main/ethereum/${item.address}/logo.png`}
                link={`https://etherscan.io/token/${item.address}`}
                icon="notifications"
              /> */}
              </Grid>
            ))}
        </Grid>

        <MDBox p={2} mt="20px" ml="auto" mr="auto">
          <Link
            
            to="/tokens"
            _blank="noreferrer"
            // variant="gradient"
            
          >
            <button className="openTokenBtn">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.6" d="M6.66699 8.66667C6.66699 13.4531 10.5472 17.3333 15.3337 17.3333C20.1201 17.3333 24.0003 13.4531 24.0003 8.66667C24.0003 3.8802 20.1201 0 15.3337 0C10.5472 0 6.66699 3.8802 6.66699 8.66667Z" fill="#FFFDF5"/>
              <path d="M0 15.3333C0 20.1198 3.8802 24 8.66667 24C13.4531 24 17.3333 20.1198 17.3333 15.3333C17.3333 10.5468 13.4531 6.66663 8.66667 6.66663C3.8802 6.66663 0 10.5468 0 15.3333Z" fill="#FFFDF5"/>
            </svg>

            Open Tokens
            </button>
          </Link>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
TokensOverview.defaultProps = { tokensTop: [] };

// Typechecking props for the DefaultInfoCard
TokensOverview.propTypes = {
  tokensTop: PropTypes.arrayOf(PropTypes.object),
  tokensNumber: PropTypes.number.isRequired,
  tokensPortoflioValue: PropTypes.number.isRequired,
};

export default TokensOverview;
